import React from 'react';
import {Card} from 'react-bootstrap';
import {getTheme, mergeStyleSets, Spinner, SpinnerSize, IBreadcrumbItem, Text, FontIcon, Link, DirectionalHint, DefaultButton, PrimaryButton} from '@fluentui/react';
import {useParams} from 'react-router-dom';
import {useCustomerById} from '../../../api/resources/customers';
import {IUserUpdateRequest, useUpdateUser, useUserById} from '../../../api/resources/users';
import { IOrderedBreadcrumbItem, PageHeader } from '../../core/PageHeader';
import { BlockUserSignInModal } from '../modals/BlockUserSignInModal';
import { UserLicenseAssignments } from '../UserLicenseAssignments';
import { ISubscription } from '../../../api/resources/subscriptions';
import { PasswordResetModal } from '../modals/PasswordResetModal';
import { AxiosError } from 'axios';
import { BaseDialog } from '../../core/BaseDialog';
import { ErrorMessage } from '../../core/ErrorMessage';
import { TenantContext } from '../../core/context/TenantContextProvider';

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        cardContent: {
            backgroundColor: theme.palette.white,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            paddingLeft: '5rem',
            paddingRight: '5rem',
            width: '100%'
        },
        loading: {
            marginTop: '8rem',
            marginBottom: '8rem',
            fontStyle: 'italic',
            fontSize: '14',
            color: 'white'
        },
        pageTitle: {
            textAlign: "left",
            paddingTop: "2rem",
            paddingBottom: "2rem",
        },
        titleContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        mutedText: {
            color: '#787878'
        },
        tintedBackground: {
            backgroundColor: "#fafafa",
            padding: "1rem",
            marginBottom: '2rem'
        },
        textLeft: {
            textAlign: 'left'
        },
        textRight: {
            textAlign: 'right'
        },
        container: {
            display: "flex",
            alignItems: "stretch"
        },
        column: {
            paddingRight: "2rem",
            flex: 1,
            textAlign: "left",
        },
        icon: {
            color: '#117492'
        },
        sectionHead: {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        licenseAssignmentsContainer: {
        },
        licenseAssignmentsTitleContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        successText: {
            color: "#98c652"
        },
        errorText: {
            color: '#ff0033'
        },
    }),
};

export const UserCard: React.FC = () => {
    const tenantContext = React.useContext(TenantContext);
    const {userId, tenantId} = useParams();
    const customerTenantId = tenantId ?? tenantContext!.customerTenantId;
    const customerQuery = useCustomerById(customerTenantId);
    const userQuery = useUserById(userId!, customerTenantId);
    const updateUserCommand = useUpdateUser();

    const [showAssignmentSuccess, setShowAssignmentSuccess] = React.useState<boolean>(false);
    const [showAssignmentError, setShowAssignmentError] = React.useState<boolean>(false);
    const [isBlockUserModalOpen, setIsBlockUserModalOpen] = React.useState<boolean>(false);
    const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = React.useState<boolean>(false);
    const [licenseAssignmentError, setLicenseAssignmentError] = React.useState<AxiosError | null>(null);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState<boolean>(false);
    const [assignmentChangesMade, setAssignmentChangesMade] = React.useState<boolean>(false);
    const [licensesToAssign, setLicensesToAssign] = React.useState<string[]>([]);
    const [licensesToRevoke, setLicensesToRevoke] = React.useState<string[]>([]);

    if(userId !== userQuery.data?.user.id) {
        userQuery.refetch();
    }

    const reset = () => {
        setIsErrorDialogOpen(false);
        setAssignmentChangesMade(false);
        setLicensesToAssign([]);
        setLicensesToRevoke([]);
        setShowAssignmentSuccess(false);
        setShowAssignmentError(false);
        userQuery.refetch();
    }

    const setSelectedLicenses = (selected: boolean, subscription: ISubscription) => {

        if(selected) {
            if(licensesToRevoke.some(l => l === subscription.productId)) {
                licensesToRevoke.splice(licensesToRevoke.indexOf(subscription.productId), 1);
            } else if(!licensesToAssign.some(l => l === subscription.productId)) {
                licensesToAssign.push(subscription.productId);
            }

        } else {
            if (licensesToAssign.some(l => l === subscription.productId)) {
                licensesToAssign.splice(licensesToAssign.indexOf(subscription.productId), 1);
            } else if(!licensesToRevoke.some(l => l === subscription.productId)) {
                licensesToRevoke.push(subscription.productId);
            } 
        }

        if(licensesToAssign.length > 0 || licensesToRevoke.length > 0)
            setAssignmentChangesMade(true);
        else
            setAssignmentChangesMade(false);

        setShowAssignmentError(false);
        setLicenseAssignmentError(null);
        setShowAssignmentSuccess(false);
    }

    const updateLicenseAssignments = () => {
        const request: IUserUpdateRequest = {
            tenantId: customerQuery.data!.tenantId,
            userId: userQuery.data!.user.id,
            licensesToAssign,
            licensesToRevoke
        }
        updateUserCommand.mutateAsync(request)
            .then(() => {
                setAssignmentChangesMade(false);
                setLicensesToAssign([]);
                setLicensesToRevoke([]);
                setShowAssignmentSuccess(true);
                setTimeout(() => {
                    setShowAssignmentError(false);
                    setShowAssignmentSuccess(false);
                    userQuery.refetch();
                }, 3000);
            },
            (reason) => {
                setShowAssignmentError(true);
                setLicenseAssignmentError(reason);
                setLicensesToAssign([]);
                setLicensesToRevoke([]);
            });
    }

    const breadcrumbs: IOrderedBreadcrumbItem[] = [];
    if(customerQuery.data && userQuery.data) {
        breadcrumbs.push({
            key: customerQuery.data.tenantId,
            text: customerQuery.data.companyName,
            href: tenantContext!.isPartnerUser ? `/customers/${customerQuery.data?.tenantId}/subscriptions` : '/subscriptions',
            position: 1
        }, {
            key: 'users',
            text: 'Users',
            href: tenantContext!.isPartnerUser ? `/customers/${customerQuery.data?.tenantId}/users` : '/users',
            position: 1
        }, {
            key: userQuery.data!.user.id,
            text: userQuery.data!.user.displayName,
            href: tenantContext!.isPartnerUser ? `/customers/${customerQuery.data?.tenantId}/users/${userQuery.data?.user.id}` : `/users/${userQuery.data?.user.id}`,
            isCurrentItem: true,
            position: 2
        });
    }

    return (
        <div>
            <Card className={styles.cardContent}>
                { (customerQuery.isLoading || customerQuery.isRefetching || userQuery.isFetching || userQuery.isRefetching) && <Spinner label="Getting user details..." className={styles.loading} size={SpinnerSize.large} /> }
                { !customerQuery.isLoading && !customerQuery.isRefetching && !userQuery.isFetching && !userQuery.isRefetching && 
                    <div>
                        <PasswordResetModal
                            hidden={!isPasswordResetModalOpen}
                            customer={customerQuery.data!}
                            user={userQuery.data!.user}
                            onDismiss={() => setIsPasswordResetModalOpen(false)} />

                        <BlockUserSignInModal
                            hidden={!isBlockUserModalOpen}
                            customer={customerQuery.data!}
                            user={userQuery.data!.user}
                            onDismiss={() => setIsBlockUserModalOpen(false)} />

                        <BaseDialog
                            hidden={!isErrorDialogOpen}
                            onDismiss={() => reset()}
                            title="Failed to assign licenses">
                                <ErrorMessage message='License assignment failed' axiosError={licenseAssignmentError!} />
                            </BaseDialog>

                        <PageHeader customer={customerQuery.data!} pageTitle={userQuery.data?.user.displayName!} breadcrumbs={breadcrumbs} />
                        <div>
                            <div className={styles.pageTitle}>
                                <div className={styles.titleContainer}>
                                    <div>
                                        <Text block variant='large'>{userQuery.data?.user.displayName}</Text>
                                        <Text block variant='small' className={styles.mutedText}>{userQuery.data?.user.id}</Text>
                                    </div>
                                    { tenantContext?.isPartnerUser &&
                                        <DefaultButton onClick={() => setIsPasswordResetModalOpen(true)}><FontIcon iconName="LifesaverLock" className={styles.icon} />&nbsp;&nbsp;Reset password</DefaultButton>
                                    }
                                </div>
                            </div>
                            <div className={`${styles.tintedBackground} ${styles.textLeft}`}>
                                <div className={styles.container}>
                                    <div className={styles.column}>
                                        <dl>
                                            <dt><Text variant="small" className={styles.mutedText}>First name</Text></dt>
                                            <dd><Text variant="small">{userQuery.data?.user.firstName}</Text></dd>
                                        </dl>
                                        <dl>
                                            <dt><Text variant="small" className={styles.mutedText}>Last name</Text></dt>
                                            <dd><Text variant="small">{userQuery.data?.user.lastName}</Text></dd>
                                        </dl>
                                        <dl>
                                            <dt><Text variant="small" className={styles.mutedText}>Email</Text></dt>
                                            <dd><Text variant="small"><Link href={`mailto:${userQuery.data?.user.email}`}>{userQuery.data?.user.email?.toLocaleLowerCase()}</Link></Text></dd>
                                        </dl>
                                    </div>
                                    <div className={styles.column}>
                                        <dl>
                                            <dt><Text variant="small" className={styles.mutedText}>State</Text></dt>
                                            <dd><Text variant="small">{userQuery.data?.user.state} | <Link onClick={() => setIsBlockUserModalOpen(true)}>{userQuery.data?.user.state === "Active" ? "Block" : "Allow"}</Link></Text></dd>
                                        </dl>
                                        <dl>
                                            <dt><Text variant="small" className={styles.mutedText}>Domain type</Text></dt>
                                            <dd><Text variant="small">{userQuery.data?.user.domainType}</Text></dd>
                                        </dl>
                                        <dl>
                                            <dt><Text variant="small" className={styles.mutedText}>Roles</Text></dt>
                                            <dd>
                                                { userQuery.data?.user.roles && userQuery.data?.user.roles.length > 0 && userQuery.data?.user.roles.map(role => <Text block variant="small"><FontIcon className={styles.icon} iconName='SecurityGroup' /> {role}</Text>) }
                                                { (!userQuery.data?.user.roles || userQuery.data.user.roles.length === 0) && <Text variant='small' className={styles.mutedText}><em>No roles assigned</em></Text> }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className={styles.licenseAssignmentsContainer}>
                                    <div className={styles.licenseAssignmentsTitleContainer}>
                                        <Text block className={styles.sectionHead}>Assigned License(s)</Text>
                                        <div className={styles.textRight}>
                                            <PrimaryButton disabled={!assignmentChangesMade || updateUserCommand.isLoading} onClick={() => updateLicenseAssignments()}>
                                                { !updateUserCommand.isLoading && showAssignmentSuccess && <FontIcon className={styles.successText} iconName="CheckMark" /> }
                                                { !updateUserCommand.isLoading && showAssignmentError && <FontIcon className={styles.errorText} iconName="Warning" /> }
                                                { updateUserCommand.isLoading && <Spinner /> }
                                                { !updateUserCommand.isLoading && !showAssignmentError && !showAssignmentSuccess && "Save" }
                                            </PrimaryButton>
                                            { showAssignmentError && <Text block className={styles.errorText} variant="small">License assignment failed. <Link onClick={() => setIsErrorDialogOpen(true)}>See details</Link></Text> }
                                        </div>
                                    </div>
                                <UserLicenseAssignments 
                                    existingLicenses={userQuery.data!.user.licenseAssignments.map(l => l.productSkuId)}
                                    onLicenseAssigned={(subscription) => setSelectedLicenses(true, subscription)} 
                                    onLicenseRevoked={(subscription) => setSelectedLicenses(false, subscription)} 
                                    tenantId={customerQuery.data!.tenantId} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Card>
        </div>
    );
}