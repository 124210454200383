import React from 'react';
import {FallbackProps} from 'react-error-boundary';
import {getTheme, mergeStyleSets} from "@fluentui/react/lib/Styling";
import logoImage from '../../images/logo.png';
import {IImageProps, Image} from "@fluentui/react";
import {Card} from "react-bootstrap";

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        cardContent: {
            backgroundColor: theme.palette.white,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            paddingLeft: '5rem',
            paddingRight: '5rem'
        },
        logo: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '5rem',
            paddingBottom: '2rem'
        },
        errorHeader: {
            fontSize: 20
        },
        errorBody: {
            fontStyle: 'italic',
            color: 'gray'
        }
    }),
};

const contentLogo: Partial<IImageProps> = {
    src: logoImage,
}

export const ErrorCard: React.FC<FallbackProps> = (props) => {
    return (
        <div>
            <Card className={styles.cardContent}>
                <Image className={styles.logo} {...contentLogo}/>
                <p className={styles.errorHeader}>¯\_(ツ)_/¯</p>
                <p className={styles.errorBody}>
                    Well, that didn't work out. <br/>
                    Error information is below. If you need help, please call us.
                </p>
                <p>{props.error.message}</p>
            </Card>
        </div>
    );

}