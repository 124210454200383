import {Endpoint} from '../../base';
import {ISubscriptionUpdateRequest, ISubscriptionResponse, ISubscriptionAddOnsResponse} from './models/';

export const getSubscriptionById = (tenantId: string, subscriptionId: string) => Endpoint
    .get<ISubscriptionResponse>(`customers/${tenantId}/subscriptions/${subscriptionId}`)
    .then((r) => r.data);

export const updateSubscription = (params: ISubscriptionUpdateRequest) => Endpoint
    .patch<ISubscriptionResponse>(`customers/${params.tenantId}/subscriptions/${params.subscriptionId}`, params)
    .then((r) => r.data);

export const getSubscriptionAddOns = (tenantId: string, subscriptionId: string) => Endpoint
    .get<ISubscriptionAddOnsResponse>(`customers/${tenantId}/subscriptions/${subscriptionId}/addons`)
    .then((r) => r.data);