import { IIconProps, mergeStyleSets, Spinner, SpinnerSize, Text } from "@fluentui/react";
import React from "react";
import { ICustomer } from "../../../api/resources/customers";
import { IUser, IUserUpdateRequest, useUpdateUser } from "../../../api/resources/users";
import { ActionDialog } from "../../core/ActionDialog";
import { ErrorMessage } from "../../core/ErrorMessage";
import { SuccessMessage } from "../../core/SuccessMessage";

const styles = {
    ...mergeStyleSets({
        container: {
            padding: '1rem'
        },
        textCenter: {
            textAlign: 'center'
        }
    })
}

export interface IBlockUserSignInModalProps {
    hidden: boolean
    customer: ICustomer
    user: IUser
    onDismiss: () => void
}

export const BlockUserSignInModal: React.FC<IBlockUserSignInModalProps> = (props) => {

    const updateUserCommand = useUpdateUser();

    const blockSignin = () => {
        const request: IUserUpdateRequest = {
            tenantId: props.customer.tenantId,
            userId: props.user.id,
            block: true
        }
        updateUserCommand.mutate(request);
    }
    
    return (
      <ActionDialog
        hidden={props.hidden}
        action={() => blockSignin()}
        confirmText="Block"
        title={`Block ${props.user.displayName}`}
        onDismiss={() => props.onDismiss()}
        errorComponent={
          <ErrorMessage
            message={`Sorry, we couldn't block ${props.user.displayName}`}
            axiosError={updateUserCommand.error ?? undefined}
          />
        }
        successComponent={
          <SuccessMessage
            message={`${props.user.displayName} has been blocked`}
            iconName="BlockContact"
          />
        }
        isLoading={updateUserCommand.isLoading}
        loadingMessage={`Blocking ${props.user.displayName} from all Office 365 services...`}
        isError={updateUserCommand.isError}
        isSuccess={updateUserCommand.isSuccess}
      >
        <Text>
          Are you sure you want to block {props.user.displayName} from signing
          in to all Office 365 services?
        </Text>
      </ActionDialog>
    );
}