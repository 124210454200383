import React from "react";
import { FontIcon, FontWeights, mergeStyleSets, PrimaryButton, Text } from "@fluentui/react";
import { IProduct, IProductSku } from "../../api/resources/products";
import { ProductPurchaseModal } from "./modals/ProductPurchaseModal";
import { ICustomer } from "../../api/resources/customers";
import { formatCurrency } from "../../services/CurrencyFormatService";

const styles = {
    ...mergeStyleSets({
        skuCompact: {
            flex: 1,
            flexBasis: '30%',
            maxWidth: '16rem',
            padding: '1rem',
            margin: '1rem',
            borderColor: '#efefef',
            borderWidth: '1px',
            borderStyle: 'solid'
        },
        skuExpanded: {
            maxWidth: '56rem',
            padding: '1rem',
            margin: '1rem',
            borderColor: '#efefef',
            borderWidth: '1px',
            borderStyle: 'solid'
        },
        skuTitle: {
            marginBottom: '1rem',
            fontWeight: FontWeights.bold
        },
        expandedSkuContainer: {
            minWidth: '16rem',
            paddingLeft: '0.5rem'
        },
        quantity: {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        buyNowButton: {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        mutedText: {
            color: '#787878'
        },
        prerequisites: {
            color: '#787878',
            marginTop: '0.5rem',
            marginBottom: '0.5rem'
        },
        marginTop: {
            marginTop: '1rem'
        },
        marginBottom: {
            marginBottom: '1rem'
        },
        marginLeft: {
            marginLeft: '1rem'
        },
        label: {
            color: '#787878'
        },
        descriptionContainer: {
            display: 'flex'
        },
        icon: {
            color: '#117492'
        }
    })
};

export interface ISkuProps {
    customer: ICustomer
    product: IProduct
    sku: IProductSku
    allProducts: IProduct[]
    expanded?: boolean
}

export const Sku: React.FC<ISkuProps> = (props) => {

    const [isPurchaseModalOpen, setIsPurchaseModalOpen] = React.useState<boolean>(false);

    const closeProductPurchaseModal = () => {
        setIsPurchaseModalOpen(false);
    }

    const getVariantName = () => {
        if(props.sku.tags.split(';').includes("Trial")) {
            if(props.sku.erpPrice == 0) {
                return "Free trial";
            }
            else if (props.sku.termDuration === "P1M") {
                return "1 month trial";
            }
            else
            {
                return "Trial";
            }
        } 
        else if (props.sku.termDuration === "P1M") {
            return "Month-to-month";
        }
        else {
            return "1-year commitment";
        }
    }

    const getPrerequisites = () => {
        const offerIds = props.sku.productSkuPrerequisites.split(',');
        return offerIds.map(offerId => {
            const productId = offerId.split('/')[0];
            const skuId = offerId.split('/')[1];

            const matchingProduct = props.allProducts.find(p => p.id === productId);
            const matchingSku = matchingProduct?.skus.find(s => s.id === skuId);
            return matchingSku?.title;
        })
        .filter(title => !!title);
    }

    return (
        <div>
            <ProductPurchaseModal
                hidden={!isPurchaseModalOpen}
                customer={props.customer}
                onDismiss={() => closeProductPurchaseModal()}
                product={props.product}
                sku={props.sku} />
            { !props.expanded && 
                <div className={styles.skuCompact}>
                    <Text className={styles.skuTitle} block>{props.sku.title}</Text>
                    <Text block>{props.sku.erpPrice == 0 ? 'Free' : `${props.sku.termDuration === 'P1Y' ? formatCurrency(props.sku.erpPrice / 12) : formatCurrency(props.sku.erpPrice)} each/monthly`}</Text>
                    <Text block className={styles.mutedText}>{getVariantName()}</Text>
                    <PrimaryButton className={styles.buyNowButton} text={props.sku.tags.split(';').includes("Trial") ? "Begin trial" : "Buy now"} onClick={() => setIsPurchaseModalOpen(true)} />
                </div>
            }
            { props.expanded &&
                <div className={styles.skuExpanded}>
                    <Text className={styles.skuTitle} block>{props.sku.title}</Text>
                    <div className={styles.descriptionContainer}>
                        <Text block className={styles.mutedText}>{props.sku.description}</Text>
                        { props.sku.productSkuPrerequisites && props.sku.productSkuPrerequisites.length > 0 && 
                            <div className={styles.expandedSkuContainer}>
                                <Text block className={`${styles.mutedText} ${styles.marginBottom}`}>Add-on for:</Text>
                                { getPrerequisites().map(prerequisite => <Text variant="small" block><FontIcon className={styles.icon} iconName="CircleAddition" /> {prerequisite}</Text>) }
                            </div>
                        }
                    </div>
                    <Text block className={`${styles.mutedText} ${styles.marginTop} ${styles.marginBottom}`}>{getVariantName()}</Text>
                    <PrimaryButton className={styles.buyNowButton} text={props.sku.tags.split(';').includes("Trial") ? "Begin trial" : "Buy now"} onClick={() => setIsPurchaseModalOpen(true)} />
                    <Text className={`${styles.mutedText} ${styles.marginLeft}`}>{props.sku.erpPrice == 0 ? 'Free' : props.sku.termDuration === "P1Y" ? `${formatCurrency(props.sku.erpPrice / 12)} per license monthly` : `${formatCurrency(props.sku.erpPrice)} per license monthly`}</Text>
                </div>
            }
        </div>
    );
};