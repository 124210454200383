import { ActionButton, Dialog, DialogContent, DialogFooter, getTheme, IDialogContentProps, IIconProps, IModalProps, mergeStyleSets } from "@fluentui/react";
import React from "react";

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        noSelect: {
            userSelect: 'none'
        },
        container: {
            padding: '1rem'
        },
        withShadow: {
            boxShadow: '0 0 2px #DEDEDE'
        }
    })
}

const defaultModalProps: IModalProps = {
    isBlocking: false,
    theme
};

const defaultCloseButtonIcon: IIconProps = {
    iconName: "Cancel"
}

const defaultCloseButtonText = "Close";

export interface IBaseDialogButtonProps {
    shouldRender: boolean
    element: JSX.Element
}

export interface IBaseDialogProps {
    hidden: boolean,
    title: string,
    onDismiss: () => void
    noBorder?: boolean,
    dialogContentProps?: IDialogContentProps,
    closeButtonText?: string,
    closeButtonIcon?: IIconProps,
    maxWidth?: number,
    minWidth?: number
    modalProps?: IModalProps
    additionalDialogButtons?: IBaseDialogButtonProps[]
    children?: React.ReactNode;
}

export const BaseDialog: React.FC<IBaseDialogProps> = (props) => {

    return(
        <Dialog
            hidden={props.hidden}
            title={props.title}
            dialogContentProps={props.dialogContentProps}
            maxWidth={props.maxWidth ?? 500}
            minWidth={props.minWidth ?? 500}
            modalProps={props.modalProps ?? defaultModalProps}
            onDismiss={props.onDismiss}>
                <DialogContent>
                    <div className={`${styles.container} ${props.noBorder ? "" : styles.withShadow}`}>
                        {props.children}
                    </div>
                </DialogContent>
            <DialogFooter>
                <ActionButton onClick={() => props.onDismiss()} iconProps={props.closeButtonIcon ?? defaultCloseButtonIcon} allowDisabledFocus>{props.closeButtonText ?? defaultCloseButtonText}</ActionButton>
                {
                    props.additionalDialogButtons && props.additionalDialogButtons.length > 0 && props.additionalDialogButtons.map(button => {
                        if(button.shouldRender)
                        return button.element;
                    })
                }
            </DialogFooter>

        </Dialog>
    );
}