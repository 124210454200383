import React, { FormEvent, useEffect } from 'react';
import {Dropdown, FontIcon, IDropdownOption, IDropdownProps, mergeStyleSets, Position, SpinButton, Spinner, SpinnerSize, Text, TooltipHost} from '@fluentui/react';
import {ICustomer, useCustomerSubscriptions} from '../../../api/resources/customers';
import { ISubscription } from '../../../api/resources/subscriptions';
import { ActionDialog } from '../../core/ActionDialog';
import { IProduct, IProductSku, useCreateProductOrder } from '../../../api/resources/products';
import { daysFromNow, formatDate } from '../../../services/DateFormatService';
import { ErrorMessage } from '../../core/ErrorMessage';
import { SuccessMessage } from '../../core/SuccessMessage';
import { formatCurrency } from '../../../services/CurrencyFormatService';

export interface IProductPurchaseModalProps {
    hidden: boolean
    onDismiss: () => void
    customer: ICustomer
    product?: IProduct
    sku?: IProductSku
}

const styles = {
    ...mergeStyleSets({
        container: {
            padding: '1rem',
            boxShadow: '0 0 2px #DEDEDE'
        },
        formControl: {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        loading: {
            marginBottom: '1rem',
            fontStyle: 'italic',
            color: 'gray'
        },
        errorText: {
            color: '#ff0033'
        }
    }),
};

export const ProductPurchaseModal: React.FC<IProductPurchaseModalProps> = (props) => {
    const automaticDateOption = { key: 'none', text: 'Automatic' };

    const subscriptions = useCustomerSubscriptions(props.customer.tenantId);
    const createOrderCommand = useCreateProductOrder();
    const [quantity, setQuantity] = React.useState<number>(1);
    const [endDateOptions, setEndDateOptions] = React.useState<{ key: string, text: string}[]>([automaticDateOption]);
    const [selectedEndDate, setSelectedEndDate] = React.useState<string>('none');

    const getEndDateOptions = () => {
        if(subscriptions.data && subscriptions.data.totalSubscriptions) {
            const activeSusbscriptions = subscriptions.data.customerSubscriptions.filter(s => s.status === 'Active');
            const subscriptionsWithMatchingTerm = activeSusbscriptions.filter(s => props.sku?.termDuration ? s.termDuration === props.sku.termDuration : "P1M");
            const sortedSubscriptions = subscriptionsWithMatchingTerm.sort((a, b) => new Date(a.commitmentEndDate).getTime() - new Date(b.commitmentEndDate).getTime());

            const options: Array<{ key: string, text: string }> = [];

            sortedSubscriptions.forEach(subscription => {
                if(options.some(o => o.key === subscription.commitmentEndDate.toString()))  {
                    return;
                }

                const sameDateCount = sortedSubscriptions.filter(s => s.commitmentEndDate === subscription.commitmentEndDate).length;
                options.push({
                    key: subscription.commitmentEndDate.toString(),
                    text: sameDateCount === 1 ? `${formatDate(new Date(subscription.commitmentEndDate))} (${subscription.offerName})` : `${formatDate(new Date(subscription.commitmentEndDate))} (${sameDateCount} subscriptions)`
                });
            });

            options.push(automaticDateOption);

            endDateOptions.splice(0, endDateOptions.length);
            endDateOptions.push(...options);
        }
    }

    const createOrder = () => {
        createOrderCommand.mutate({
            billingCycle: props.sku ? props.sku.billingPlan : "",
            commitmentEndDate: selectedEndDate === automaticDateOption.key ? undefined : new Date(selectedEndDate),
            country: 'US',
            productId: props.product ? props.product.id : "",
            quantity: quantity,
            skuId: props.sku ? props.sku.id : "",
            tenantId: props.customer.tenantId,
            term: props.sku ? props.sku.termDuration : ""
       });
    }

    const close = () => {
        setSelectedEndDate(automaticDateOption.key)
        props.onDismiss();
    }

    const endDateLabel = (props: IDropdownProps | undefined): JSX.Element => {
        return (
            <Text>{props?.label} <TooltipHost content="Aligning an end date to an existing subscription ensures that those subscriptions end on the same date versus having each subscription end on a different day of the year. This subscription will be prorated where necessary based on the end date selected."><FontIcon iconName="Info" /></TooltipHost></Text>
        );
    }

    const onEndDateSelected = (_event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined): void => {
        if (!option) {
            return;
        }
        setSelectedEndDate((option.key as string));
    }

    getEndDateOptions();

    return (
        <ActionDialog
            hidden={props.hidden}
            action={() => createOrder()}
            onDismiss={() => close()}
            title={props.sku ? props.sku.title : ''}
            isLoading={createOrderCommand.isLoading}
            loadingMessage='Creating order...'
            isError={createOrderCommand.isError}
            isSuccess={createOrderCommand.isSuccess}
            errorComponent={<ErrorMessage message={`Sorry, we couldn't create that order.`} axiosError={createOrderCommand.error ?? undefined} />}
            successComponent={<SuccessMessage message="Your order has been processed successfully. It may take up to 30 minutes before the subscriptions shows up in the Subscriptions panel." />}
            confirmText="Purchase">
                <div>
                    <Text>You will be billed {props.sku?.termDuration === "P1Y" ? formatCurrency(props.sku.erpPrice / 12) : formatCurrency(props.sku ? props.sku.erpPrice : 0)} monthly per license{props.sku?.termDuration === "P1Y" ? " for a period of 1 year" : ""}.</Text>
                    <SpinButton className={styles.formControl} styles={{ spinButtonWrapper: { width: 75 }}} label="Quantity" labelPosition={Position.top} min={1} max={props.sku?.maxLicenses} onChange={(_event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined) => setQuantity(newValue ? Number(newValue) : 0)} />
                    { subscriptions.data && subscriptions.data.customerSubscriptions.length > 0 && props.sku?.erpPrice != 0 && <Dropdown label="End date" onRenderLabel={endDateLabel} className={styles.formControl} styles={ { dropdown: { width: 350 } }} options={endDateOptions} defaultSelectedKey={endDateOptions[0].key} onSelect={onEndDateSelected} /> } 
                </div>
        </ActionDialog>
    );
}