import { FontIcon, List, mergeStyleSets, Spinner, SpinnerSize, Text, Toggle, TooltipHost } from "@fluentui/react";
import React from "react";
import { useCustomerSubscriptions } from "../../api/resources/customers";
import { ISubscription } from "../../api/resources/subscriptions";
import { getIconName } from "../../services/ProductIconService";

const styles = {
    ...mergeStyleSets({
        subscriptionListItemContainer: {
            display: 'flex',
            // justifyContent: 'stretch',
            alignItems: 'center',
            boxShadow: '0 0 2px #DEDEDE',
            padding: '.1rem'
        },
        mutedText: {
            color: '#787878'
        },
        flexStart: {
            justifySelf: 'left',
            minWidth: '15%',
            textAlign: 'center'
        },
        flexCenter: {
            justifySelf: 'stretch',
            width: '70%',
            paddingRight: '1rem'
        },
        flexEnd: {
            justifySelf: 'right',
            maxWidth: '15%'
        }
    })
}

export interface IUserLicenseAssignmentProps {
    tenantId: string
    existingLicenses?: string[]
    onLicenseAssigned: (subscription: ISubscription) => void
    onLicenseRevoked: (subscription: ISubscription) => void
}

export const UserLicenseAssignments: React.FC<IUserLicenseAssignmentProps> = (props) => {

    const updateSelectedLicenses = (checked: boolean | undefined, subscription: ISubscription) => {
        if(checked) {
            props.onLicenseAssigned(subscription);
        } else {
            props.onLicenseRevoked(subscription);
        }
    }

    const formatSubscriptions = (item?: ISubscription | undefined, _index?: number | undefined, _isScrolling?: boolean | undefined) => {
        if(!item) {
            return <></>;
        }

        const numberOfLicensesAvailable = item.quantity - item.assignedQuantity;
        const hasLicensesAvailable = numberOfLicensesAvailable !== 0;
        const isAssigned = props.existingLicenses ? props.existingLicenses.some(l => l === item.productId) : false;

        return(

            <div className={styles.subscriptionListItemContainer}>
                <div className={styles.flexStart}>
                    <Text block variant="xxLarge" className={styles.mutedText}><FontIcon iconName={getIconName(item.offerName, false)} /></Text>
                </div>
                <div className={styles.flexCenter}>
                    <TooltipHost content={item.offerName}><Text block className={item.status === "Active" && hasLicensesAvailable ? '' : styles.mutedText } nowrap>{item.offerName}</Text></TooltipHost>
                    <Text className={styles.mutedText}>{numberOfLicensesAvailable ? numberOfLicensesAvailable : "No"} license(s) available</Text>
                </div>
                <div className={styles.flexEnd}>
                    <Toggle disabled={!hasLicensesAvailable && !isAssigned } defaultChecked={props.existingLicenses ? props.existingLicenses.some(license => license === item.productId) : false} onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => updateSelectedLicenses(checked, item)} />
                </div>
            </div>
        );
    }

    const customerSubscriptionsQuery = useCustomerSubscriptions(props.tenantId);

    return (
        <div>
            { (customerSubscriptionsQuery.isLoading || customerSubscriptionsQuery.isRefetching) && <Spinner label="Getting available licenses..." size={SpinnerSize.large} /> }
            { !customerSubscriptionsQuery.isLoading && !customerSubscriptionsQuery.isRefetching && customerSubscriptionsQuery.data && customerSubscriptionsQuery.data.customerSubscriptions.length > 0 && <List items={customerSubscriptionsQuery.data ? customerSubscriptionsQuery.data.customerSubscriptions.filter(s => s.status === "Active") : []} onRenderCell={formatSubscriptions} /> }
            { !customerSubscriptionsQuery.isLoading && !customerSubscriptionsQuery.isRefetching && (!customerSubscriptionsQuery.data || customerSubscriptionsQuery.data.customerSubscriptions.length == 0) && <Text className={styles.mutedText}><em>No subscriptions available. Please purchase a subscription to assign a license.</em></Text> }
        </div>
    );
}