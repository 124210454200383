import React from 'react';

export const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div>
            <footer>
                <hr/>
                <p className="small text-secondary text.center">
                    &copy; {currentYear} {process.env.REACT_APP_COMPANY_NAME}
                </p>
            </footer>
        </div>
    );
}