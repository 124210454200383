import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import {IImageProps, Image, ImageFit, mergeStyleSets} from "@fluentui/react";
import {Button} from "react-bootstrap";
import officeLogoImage from "../../images/office-365.png";

const styles = {
    ...mergeStyleSets({
        loginButton: {
            shadow: 'none',
            width: '60%',
            backgroundColor: 'inherit',
        },
        logo: {
            float: 'left'
        }
    }),
};

function handleLogin(instance: any) {
    instance.loginRedirect(loginRequest).catch((e: any) => {
        console.error(e);
    });
}

const officeLogo: Partial<IImageProps> = {
    src: officeLogoImage,
    imageFit: ImageFit.contain,
    height: 24
}

export const SignInButton: React.FC = () => {
    const { instance } = useMsal();

    return (
        <div>
            <Button variant="outline-primary" className={styles.loginButton} onClick={() => handleLogin(instance)}>
                <Image className={styles.logo} {...officeLogo}></Image>Login with Office 365
            </Button>
        </div>
    );
}