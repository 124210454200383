import {AxiosError} from 'axios';
import {QueryClient} from 'react-query';

export type TQueryKey = string[];

export const onMutateHelper = (
  qc: QueryClient,
  variables: any,
  invalidate: string[],
  op?: 'create' | 'update' | 'delete',
  list?: string[],
  idField?: string,
) => {
  // First cancel any pending queries as needed
  invalidate.forEach((key) => qc.cancelQueries(key).catch((e) => console.warn('Error canceling query', key, e.message)));

  const context = {} as Record<string, any>;

  if (op && list && idField) {
    context.previousData = qc.getQueryData(list);

    let newData = null as any;
    if (op === 'create') {
      // Append a new fake object to the local list. Add a fake ID as well, until we get the server's new copy
      newData = [...(context.previousData || []), {...variables}];
    } else if (op === 'update') {
      // Find and update the record in our local data set
      if (context.previousData && context.previousData.map) {
        newData = context.previousData.map((record: any) => (record[idField] !== variables[idField] ? record : {...record, ...variables}));
      }
    } else if (op === 'delete') {
      // Find and remove the record from our local data set
      newData = context.previousData?.filter((record: any) => record[idField] !== variables[idField]);
    }

    qc.setQueryData(list, newData);
  }

  return context;
};

export const onErrorHelper = (qc: QueryClient, err: AxiosError, context: any, list?: string[]) => {
  console.warn('Mutation error', err, err.response?.data);
  if (list && context?.previousData) {
    qc.setQueryData(list, context.previousData);
  }
};

export const onSettledHelper = (qc: QueryClient, invalidate: string[]) => {
  invalidate.forEach((invalidateKey) =>
    qc.invalidateQueries(invalidateKey).catch((e) => console.warn('Error invalidating query', invalidateKey, e.message)),
  );
};
