import React from 'react';
import {Card} from "react-bootstrap";
import {IImageProps, Image} from "@fluentui/react";
import {SignInButton} from "./SignInButton";
import {getTheme, mergeStyleSets} from "@fluentui/react/lib/Styling";
import logoImage from "../../images/logo.png";


const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        cardContent: {
            backgroundColor: theme.palette.white,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            paddingLeft: '5rem',
            paddingRight: '5rem'
        },
        logo: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '5rem',
            paddingBottom: '2rem'
        },
        welcomeText: {
            textTransform: 'uppercase',
            fontWeight: 'bold'
        },
        signInButton: {
            paddingTop: '2rem',
            paddingBottom: '3rem'
        }
    }),
};

const contentLogo: Partial<IImageProps> = {
    src: logoImage,
}

export const SignInCard: React.FC = () => {

    return (
        <div>
            <Card className={styles.cardContent}>
                <Image className={styles.logo} {...contentLogo}/>
                <p className={styles.welcomeText}>Welcome to the {process.env.REACT_APP_COMPANY_NAME} Office 365 Store</p>
                <p>Please log in with your Office 365 account to manage your subscriptions</p>
                <div className={styles.signInButton}>
                    <SignInButton></SignInButton>
                </div>
            </Card>
        </div>
    );
}