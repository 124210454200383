import React from 'react';
import {useMsal, useAccount} from '@azure/msal-react';
import axios, {InternalAxiosRequestConfig, AxiosRequestHeaders} from 'axios';
import {loginRequest} from "../../authConfig";
import {Endpoint} from '../../api';

interface RequestInterceptorProps {
    children: JSX.Element,
}

export const RequestInterceptor: React.FC<RequestInterceptorProps> = ( {children} : RequestInterceptorProps) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);


    Endpoint.interceptors.request.use(async (config : InternalAxiosRequestConfig) => {

        if (account) {
            if (config.headers === undefined) {
              config.headers = {} as AxiosRequestHeaders;
            }
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account,
            });

            const bearer = `Bearer ${response.accessToken}`;
            config.headers.Authorization = bearer;

          }
          return config;
    });

    return (
        <>
            {children}
        </>
    );
};