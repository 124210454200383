import { mergeStyleSets, Text } from "@fluentui/react";
import React from "react";
import { Badge } from "react-bootstrap";
import { ICustomer } from "../../api/resources/customers";
import { IProduct } from "../../api/resources/products";
import { Sku } from "./Sku";

const styles = {
    ...mergeStyleSets({
        container: { 
            textAlign: 'left',
            padding: '1rem',
            maxWidth: '46rem'
        },
        skusContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: '2rem'
        },
        column: {
            paddingRight: '1rem',
            flex: 1,
            textAlign: 'left'
        },
        label: {
            color: '#787878'
        }
    })
};

export interface IProductExpandProps {
    product: IProduct,
    allProducts: IProduct[]
    customer: ICustomer
}

export const ProductExpand: React.FC<IProductExpandProps> = (props) => {
    return (
        <div className={styles.container}>
            <dl>
                <dt><Text variant="small" className={styles.label}>Description</Text></dt>
                <dd><Text variant="small">{props.product.description}</Text></dd>
            </dl>
            <div className={styles.skusContainer}>
                { props.product.skus.map(sku => <Sku product={props.product} sku={sku} allProducts={props.allProducts} customer={props.customer} />)}
            </div>
        </div>
    );
}