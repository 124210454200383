import {Endpoint} from '../../base';
import {IUsersResponse, IUsersQuery, IUserResponse, IUserCreateRequest, IUserLicense} from './models';
import {IUserUpdateRequest} from "./models/IUserUpdateRequest";

export const getUsers = (tenantId: string, config: IUsersQuery) => Endpoint
    .get<IUsersResponse>(`/customers/${tenantId}/users`, {params: {
        filter: config.filter,
        orderBy: config.orderBy,
        asc: config.asc,
        page: config.page,
        pageSize: config.pageSize
    }})
    .then((r) => r.data);

export const getUserById = (userId: string, tenantId: string) => Endpoint
    .get<IUserResponse>(`/customers/${tenantId}/users/${userId}`)
    .then((r) => r.data);

export const updateUser = (userUpdateRequest: IUserUpdateRequest) => Endpoint
    .patch<IUserUpdateRequest>(`customers/${userUpdateRequest.tenantId}/users/${userUpdateRequest.userId}`, userUpdateRequest)
    .then((r) => r.data);

export const createUser = (userCreateRequest: IUserCreateRequest) => Endpoint
    .post<IUserCreateRequest>(`customers/${userCreateRequest.tenantId}/users`, {
            tenantId: userCreateRequest.tenantId,
            displayName: userCreateRequest.displayName,
            firstName: userCreateRequest.firstName,
            lastName: userCreateRequest.lastName,
            emailAddress: userCreateRequest.emailAddress,
            password: userCreateRequest.password,
            forcePasswordChange: userCreateRequest.forcePasswordChange,
            usageLocation: userCreateRequest.usageLocation,
            licenseAssignments: userCreateRequest.licenseAssignments
    })
    .then((r) => r.data);