import React from 'react';
import {ICustomer} from "../../api/resources/customers";
import {mergeStyleSets} from '@fluentui/react';

export interface ICustomerQuickLinksState {
    customer: ICustomer
}

const styles = {
    ...mergeStyleSets({
        quickLinksContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '1rem',
            paddingRight: '1rem'
        },
        quickLink: {
            paddingLeft: "1rem"
        },
    })
};

export const CustomerQuickLinks: React.FC<ICustomerQuickLinksState> = (props) => {
    const azureADAdminUri = `https://aad.portal.azure.com/${props.customer.microsoftDomain}`;
    const office365AdminUri = `https://portal.office.com/Partner/BeginClientSession.aspx?CTID=${props.customer.tenantId}&CSDEST=o365admincenter`;

    return (
        <div className={styles.quickLinksContainer}>
            <div className={styles.quickLink}>
                <a href={office365AdminUri} target="_blank">
                    <img src={require('../../images/office-365.png')} width={25}/>
                </a>
            </div>
            <div className={styles.quickLink}>
                <a href={azureADAdminUri} target="_blank">
                    <img src={require('../../images/azure.png')} width={25}/>
                </a>
            </div>
        </div>
    );
}