import React from 'react';
import {generatePath, Navigate, Route, Routes} from 'react-router-dom';
import {CustomersCard, CustomerCard} from '../components/customers';
import {UserCard} from '../components/users';
import {ProductCard} from '../components/products';
import {SubscriptionCard} from "../components/subscriptions/views/SubscriptionCard";
import {AddOnsCard} from "../components/products/views/AddOnsCard";
import { TenantContext } from '../components/core/context/TenantContextProvider';
import { useIsAuthenticated } from '@azure/msal-react';

export const MainRouter: React.FC = () => {

    const tenantContext = React.useContext(TenantContext);
    
    return (
        <Routes>

            {/* Initial Route */}
            <Route path={'/'} element={ tenantContext!.isPartnerUser ? <Navigate to="/customers" /> : <Navigate to="/subscriptions" />} />

            {/* Partner Routes */}
            { tenantContext!.isPartnerUser && 
            <>
                <Route path={'/customers'} element={<CustomersCard />}/>
                <Route path={'/customers/:id/subscriptions'} element={<CustomerCard tab="subscriptions" />} />
                <Route path={'/customers/:id/users'} element={<CustomerCard tab="users" />} />
                <Route path={'/customers/:id/profile'} element={<CustomerCard tab="profile" />} />
                <Route path={'/customers/:id/store'} element={<CustomerCard tab="store" />} />
                <Route path={'/customers/:tenantId/products/:productId'} element={<ProductCard />}/>
                <Route path={'/customers/:tenantId/users/:userId'} element={<UserCard />}/>
                <Route path={'/customers/:tenantId/subscriptions/:subscriptionId'} element={<SubscriptionCard />}/>
                <Route path={'/customers/:tid/subscriptions/:sid/addons'} element={<AddOnsCard />}/>
            </> 
            }

            {/* Customer Routes */}
            { !tenantContext!.isPartnerUser &&
            <>
                <Route path={'/subscriptions'} element={<CustomerCard tenantId={tenantContext!.customerTenantId} tab="subscriptions" />} />
                <Route path={'/users'} element={<CustomerCard tenantId={tenantContext!.customerTenantId} tab="users" />} />
                <Route path={'/profile'} element={<CustomerCard tenantId={tenantContext!.customerTenantId} tab="profile" />} />
                <Route path={'/store'} element={<CustomerCard tenantId={tenantContext!.customerTenantId} tab="store" />} />
                <Route path={'/products/:productId'} element={<ProductCard />}/>
                <Route path={'/users/:userId'} element={<UserCard />}/>
                <Route path={'/subscriptions/:subscriptionId'} element={<SubscriptionCard />}/>
                <Route path={'/subscriptions/:subscription/addons'} element={<AddOnsCard />}/>
            </>
            }

        </Routes>
    );
};
