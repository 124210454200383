import React from 'react';
import { mergeStyleSets, Text, Link, FontIcon, DefaultButton, DirectionalHint, getTheme } from '@fluentui/react';
import { IProduct, IProductSku } from '../../api/resources/products';
import BootstrapTable from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';
import { ProductExpand } from './ProductExpand';
import { Badge } from 'react-bootstrap';
import { ProductPurchaseModal } from './modals/ProductPurchaseModal';
import { ICustomer } from '../../api/resources/customers';
import { ISubscription } from '../../api/resources/subscriptions';
import { getIconName } from '../../services/ProductIconService';
import { TenantContext } from '../core/context/TenantContextProvider';
import { formatCurrency } from '../../services/CurrencyFormatService';

export interface IProductsListState {
    products: IProduct[]
    customer: ICustomer
}

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        tableTitle: {
            marginTop: '2rem',
            textAlign: 'left'
        },
        tableContainer: {
            dixplay: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        subscriptionListContainer: {
          marginTop: '2rem',
          marginBottom: '4rem'
        },
        table: {
            fontWeight: 'normal',
            tableLayout: 'initial !important',
            borderCollapse: 'separate !important',
            borderColor: 'transparent !important'
        },    
        tableHeader: {
          color: '#6c757d'
        },
        rows: {
          boxShadow: `0 0 2px ${theme.palette.neutralSecondaryAlt}`,
          verticalAlign: 'middle'
        },
        rowTitleContainer: {
          display: 'flex',
          alignItems: 'center'
        },
        offerNameContainer: {

        },
        productImage: {
          marginRight: '1rem',
          color: '#787878'
        },
        productRow: {
          display: 'flex',
          justifyContent: 'space-between'
        },
        extendedPanel: {
          padding: '0px !important',
          backgroundColor: "#fafafa !important"
        },
        mutedText: {
          color: '#787878'
        },
        icon: {
          cursor: 'pointer',
          transition: 'all 0.2s',
          userSelect: 'none'
        },
        iconExpanded: {
          cursor: 'pointer',
          transition: 'all 0.2s',
          transform: 'rotate(90deg)',
          color: '#98C652',
          fontWeight: 'bold',
          userSelect: 'none'
        },
        loading: {
          marginTop: '8rem',
          marginBottom: '8rem',
          fontStyle: 'italic',
          fontSize: '14',
          color: 'white'
      }
    })
}

export const ProductsList: React.FC<IProductsListState> = (props) => {

    const tenantContext = React.useContext(TenantContext);
    const navigate = useNavigate();

    const goToOfferDetailPage = (offerId: string) => {
      const productDetailsPath = tenantContext!.isPartnerUser ? `/customers/${props.customer.tenantId}/products/${offerId}` : `/products/${offerId}`;
      navigate(productDetailsPath);
    }

    const [isProductPurchaseModalVisible, setIsProductPurchaseModalVisible] = React.useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = React.useState<IProduct>();
    const [selectedSku, setSelectedSku] = React.useState<IProductSku>();

    const openProductPurchaseModal = (product: IProduct, sku: IProductSku) => {
      setSelectedProduct(product);
      setSelectedSku(sku);
      setIsProductPurchaseModalVisible(true);
    }

    const closeProductPurchaseModal = () => {
      setSelectedProduct(undefined);
      setSelectedSku(undefined);
      setIsProductPurchaseModalVisible(false);
    }

    const getPurchaseButtonText = (row: IProduct, sku: IProductSku) => {
      if(row.skus.filter(s => s.termDuration === "P1Y").length === 1 && row.skus.filter(s => s.termDuration === "P1M").length === 1) {
        return `Purchase ${sku.termDuration === "P1Y" ? `w/ 1-year commitment (${formatCurrency(sku.erpPrice / 12)}/mo)`: `month-to-month (${formatCurrency(sku.erpPrice)}/mo)`}`
      } else if (sku.tags.split(';').includes("Trial")) {
        if(sku.erpPrice == 0) {
          return 'Begin free trial';
         } else if(sku.termDuration === "P1M") {
            return 'Begin 1-month trial';
        } else {
          return 'Begin trial';
        }
      }
      return `Purchase ${sku.title} ${sku.termDuration === "P1Y" ? `w/ 1-year commitment (${formatCurrency(sku.erpPrice / 12)}/mo)`: `month-to-month (${formatCurrency(sku.erpPrice)}/mo)`}`
    }

    const offerNameColumn = (cell: any, row: IProduct) => {
        return (
          <div className={styles.productRow}>
            <div>
              <div className={styles.rowTitleContainer}>
                <Text className={styles.productImage} variant='xxLarge'><FontIcon iconName={getIconName(row.title, row.skus.some(s => s.productSkuPrerequisites))} /></Text>
                <div className={styles.offerNameContainer}>
                  <div><Link onClick={() => goToOfferDetailPage(row.id)}>{row.title}</Link></div>
                  <Text variant="small" className={styles.mutedText}>{row.skus.some(s => s.productSkuPrerequisites) && <Badge bg="secondary">Add-on</Badge>} {row.id}</Text>
                </div>
              </div>
            </div>
            <DefaultButton text={`Options (${row.skus.length})`} menuProps={
              {
                directionalHint: DirectionalHint.bottomRightEdge,
                items: row.skus.map(sku => {
                  return {
                    key: `purchase${row.id}:${sku.id}:${sku.termDuration}`,
                    text: getPurchaseButtonText(row, sku),
                    onClick: () => openProductPurchaseModal(row, sku)
                  }
              })
            }} />
          </div>
        )
      };

    const columns = [{
        dataField: "offerName",
        text: "",
        sort: true,
        align: 'left',
        headerAlign: 'left',
        formatter: offerNameColumn
    }]

    const productExpansion = {
        onlyOneExpanding: true,
        showExpandColumn: true,
        expandByColumnOnly: true,
        className: styles.extendedPanel,
        expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => (<span></span>),
        expandColumnRenderer: ({ expanded }: { expanded: boolean }) => (<FontIcon aria-label="Toggle offer details" iconName="ChevronRight" className={expanded ? styles.iconExpanded : styles.icon}></FontIcon>),
        renderer: (product: IProduct) => <ProductExpand product={product} allProducts={props.products} customer={props.customer} />
      }

    return (
      <div>
          <ProductPurchaseModal
            customer={props.customer}
            hidden={!isProductPurchaseModalVisible}
            onDismiss={() => closeProductPurchaseModal()}
            product={selectedProduct}
            sku={selectedSku}
           />
          <BootstrapTable
              keyField="id"
              classes={styles.table}
              headerWrapperClasses={styles.tableHeader}
              rowClasses={styles.rows}
              bordered={false}
              data={props.products}
              columns={columns}
              expandRow={productExpansion}
              />
      </div>
    );
}