import {Endpoint} from '../../base';
import {
    IProductsQuery,
    IProductsResponse,
    IProductResponse,
    IProductOrderRequest,
    IProductOrderResponse,
    IProductAddOnsResponse
} from './models';

export const getProducts = (config: IProductsQuery) => Endpoint
    .get<IProductsResponse>('/products', {params: {
            filter: config.filter,
            orderBy: config.orderBy,
            asc: config.asc,
            page: config.page,
            pageSize: config.pageSize
        }})
    .then((r) => r.data);


export const getProductById = (productId: string) => {
    return Endpoint
        .get<IProductResponse>(`/products/${productId}`)
        .then((r) => r.data);
}

export const createProductOrder = (params: IProductOrderRequest) => Endpoint
    .post<IProductOrderResponse>(`customers/${params.tenantId}/orders`, params)
    .then((r) => r.data);

export const getAvailableAddOns = (offerId: string) => Endpoint
    .get<IProductAddOnsResponse>('products/addons', {params: {
        offerId: offerId
    }})
    .then((r) => r.data);