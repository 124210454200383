export const getIconName = (productName: string, isAddon?: boolean) => {
    const normalizedName = productName.toLowerCase();

    if(isAddon) {
        return 'OfficeAddinsLogo';
    }

    if(normalizedName.includes("microsoft defender")) {
        return 'ATPLogo'
    }

    if(normalizedName.includes("cloud app security")
        || normalizedName.includes("entra")) {
        return 'LockSolid'
    }

    if(normalizedName.includes("enterprise mobility")
        || normalizedName.includes("microsoft 365")
        || normalizedName.includes("office 365")
        || normalizedName.includes("viva")
        || normalizedName.includes("common area phone")) {
        return 'OfficeLogo';
    }

    if(normalizedName.includes("exchange")) {
        return 'ExchangeLogo'
    }

    if(normalizedName.includes('azure active directory')) {
        return 'AADLogo';
    }

    if(normalizedName.includes('ai')
        || normalizedName.includes('azure')) {
            return 'AzureLogo';
        }
    
    if(normalizedName.includes("teams")) {
        return 'TeamsLogo'
    }

    if(normalizedName.includes("onedrive")) {
        return 'OneDriveLogo';
    }

    if(normalizedName.includes("power apps")
        || normalizedName.includes("common data service")) {
        return 'PowerAppsLogo';
    }

    if(normalizedName.includes("microsoft flow")
        || normalizedName.includes("power automate")) {
        return 'MicrosoftFlowLogo'
    }

    if(normalizedName.includes("power bi")) {
        return 'PowerBILogo';
    }

    if(normalizedName.includes("project")) {
        return 'ProjectLogo32'
    }

    if(normalizedName.includes("sharepoint")) {
        return 'SharepointLogo';
    }

    if(normalizedName.includes("visio")) {
        return 'VisioLogo';
    }

    if(normalizedName.includes("windows")) {
        return 'WindowsLogo';
    }

    if(normalizedName.includes("dynamics")
        || normalizedName.includes("d365")) {
            return 'Dynamics365Logo';
    }

    return 'AzureLogo';
}