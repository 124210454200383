import { CommandBar, Dropdown, FontSizes, FontWeights, ICommandBarItemProps, IDropdownOption, Label, mergeStyleSets } from "@fluentui/react";
import React from "react";

export interface IPaginatorState {
    totalItems: number
    itemsPerPage: number
    itemsPerPageOptions: number[]
    currentPage: number
    onPageChange: (page: number) => void
    onItemsPerPageChange: (itemsPerPage: number) => void
    maxVisiblePageButtons?: number
}

const styles = {
    ...mergeStyleSets({
        container: {
            paddingTop: '2rem',
            width: '100%'
        },
        commandBar: {
            display: 'flex',
            aignItems: 'center',
            justifyContent: 'center'
        },
        pageSizeContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        pageSizeOptions: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: FontSizes.small
        },
        action: {
            color: '#117492'
        },
        label: {
            color: '#787878',
            fontWeight: FontWeights.regular,
            paddingLeft: '1rem',
            paddingRight: '1rem'
        }
    }),
};

export const FluentPaginator: React.FC<IPaginatorState> = (props) => {

    const lastPage = Math.ceil(props.totalItems / props.itemsPerPage);

    const goToFirstPage = () => goToPage(1);

    const goToPreviousPage = () => {
        const previousPage = props.currentPage - 1;
        goToPage(previousPage);
    }
    
    const goToPage = (pageNumber: number) => {
        props.onPageChange(pageNumber);
    };

    const goToNextPage = () => {
        const nextPage = props.currentPage + 1;
        goToPage(nextPage);
    }

    const goToLastPage = () => goToPage(lastPage);

    const items: ICommandBarItemProps[] = [
        {
            key: 'firstPage',
            iconOnly: true,
            iconProps: { iconName: 'DoubleChevronLeft' },
            onClick: () => goToFirstPage(),
            disabled: props.currentPage === 1,
            tooltipHostProps: { content: "First page" }
        },
        {
            key: 'prevPage',
            iconOnly: true,
            iconProps: { iconName: 'ChevronLeft' },
            onClick: () => goToPreviousPage(),
            disabled: props.currentPage === 1,
            tooltipHostProps: { content: "Previous page" }
        }
    ]

    const maxVisiblePages = props.maxVisiblePageButtons ?? 5;
    if(lastPage <= maxVisiblePages) {
        for(var page = 1; page <= lastPage; page++) {
            let pageToGoTo = page;
            items.push({
                key: `page${page}`,
                text: (page).toString(),
                onClick: () => goToPage(pageToGoTo),
                disabled: props.currentPage === page,
                tooltipHostProps: { content: `Page ${page}`}
            });
        }
    } else {
        let numberOfPagesOnEitherSideOfCurrentPageButton = Math.min(maxVisiblePages % 2 === 0 ? maxVisiblePages / 2 : (maxVisiblePages - 1) / 2, lastPage);
        let lowestLeftSidePageButton = props.currentPage - numberOfPagesOnEitherSideOfCurrentPageButton;
        let highestRightSidePageButton = props.currentPage + 1 + numberOfPagesOnEitherSideOfCurrentPageButton;

        for(var page = 1; page <= lastPage; page++) {
            if(page < lowestLeftSidePageButton && !items.some(i => i.key === 'previousOverlfow')) {
                items.push({
                    key: `previousOverlfow`,
                    text: '. . .',
                    onClick: () => goToPage(Math.max(lowestLeftSidePageButton - 1, 1)),
                    renderedInOverflow: false
                });
            }
            if(page >= lowestLeftSidePageButton && page < highestRightSidePageButton) {
                let pageToGoTo = page;
                items.push( {
                    key: `page${page}`,
                    text: (page).toString(),
                    onClick: () => goToPage(pageToGoTo),
                    disabled: props.currentPage === page,
                    tooltipHostProps: { content: `Page ${page}`},
                    renderedInOverflow: false
                });
            }
            if(page > highestRightSidePageButton && !items.some(i => i.key === 'nextOverflow')) {
                items.push({
                    key: `nextOverflow`,
                    text: '. . .',
                    onClick: () => goToPage(Math.min(highestRightSidePageButton, lastPage)),
                    renderedInOverflow: false
                });
            }
        }
    }
    items.push({
        key: 'nextPage',
        iconOnly: true,
        iconProps: { iconName: 'ChevronRight' },
        onClick: () => goToNextPage(),
        disabled: props.currentPage === lastPage,
        tooltipHostProps: { content: "Next page" },
        renderedInOverflow: false
    },
    {
        key: 'lastPage',
        iconOnly: true,
        iconProps: { iconName: 'DoubleChevronRight' },
        onClick: () => goToLastPage(),
        disabled: props.currentPage === lastPage,
        tooltipHostProps: { content: "Last page" },
        renderedInOverflow: false
    });

    const itemsPerPageItems: IDropdownOption[] = props.itemsPerPageOptions.map(quantity => {
        return {
            key: quantity,
            text: quantity.toString(),
            disabled: props.itemsPerPage === quantity,
            tooltipHostProps: { content: `${quantity} items per page` }
        };
    });

    return(
        <div className={styles.container}>
            <CommandBar items={items} className={styles.commandBar} shiftOnReduce={false} ariaLabel="Pagination options" />
            <div className={styles.pageSizeContainer}>
                <Label className={styles.label}>Page size</Label>
                <Dropdown options={itemsPerPageItems} className={styles.pageSizeOptions} ariaLabel="Items per page selection" selectedKey={props.itemsPerPage} onChange={(_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => props.onItemsPerPageChange(option ? Number(option.text) : props.itemsPerPageOptions[0])} />
            </div>
        </div>
    );
}