import { FontIcon, mergeStyleSets, Text, TooltipHost } from "@fluentui/react";
import { ISubscription } from "../../api/resources/subscriptions"
import { formatCurrency } from "../../services/CurrencyFormatService";
import { formatDate, daysFromNow, formatExpirationMessage } from "../../services/DateFormatService";

import React from 'react';
import { TenantContext } from '../core/context/TenantContextProvider';

export interface ILicenseSubscriptionExpandState {
  subscription: ISubscription
}

const styles = {
  ...mergeStyleSets({
    container: {
      display: 'flex',
      alignItems: 'stretch',
      paddingTop: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem'
    },
    lowerContainer: {
      display: 'flex',
      alignItems: 'stretch',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '1rem'
    },
    column: {
      paddingRight: '1rem',
      flex: 1,
      textAlign: 'left'
    },
    label: {
      color: '#787878'
    },
    warningIcon: {
      marginLeft: '0.5rem',
      color: '#ff8800'
    },
    expirationNotice: {
      backgroundColor: 'rgba(255, 136, 0, 0.5)'
    },
    expirationWarning: {
      backgroundColor: 'rgba(255, 0, 51, 0.5)'
    },
    expirationNoticeIcon: {
      fontSize: '14px',
      color: '#ff8800'
    },
    expirationWarningIcon: {
      fontSize: '14px',
      color: '#ff0033'
    }
  })
};

export const SubscriptionExpand: React.FC<ILicenseSubscriptionExpandState> = (props) => {
  const tenantContext = React.useContext(TenantContext);

  const commitmentEndDate = new Date(props.subscription.commitmentEndDate);
  const isAnnualTerm = props.subscription.termDuration === "P1Y";
  let daysTillExpiration = daysFromNow(commitmentEndDate);
  const renewsOnComponent = () => {
    if (isAnnualTerm) {
      if (daysTillExpiration <= 30)
        return renewsOnComponents(styles.expirationWarningIcon, styles.expirationWarning);

      if (daysTillExpiration <= 60)
        return renewsOnComponents(styles.expirationNoticeIcon, styles.expirationNotice);
    }
    return renewsOnComponents("", "");
  }

  const renewsOnComponents = (iconStyle: string, valueStyle: string) => {
    return <dl>
      <dt><Text variant="small" className={styles.label}>Renews On&nbsp;
        {isAnnualTerm && daysTillExpiration <= 60 && <TooltipHost content={formatExpirationMessage(daysTillExpiration)}><FontIcon className={iconStyle} iconName="Warning" /></TooltipHost>}
      </Text></dt>
      <dd><Text variant="small" className={valueStyle}>{formatDate(commitmentEndDate)}</Text></dd>
    </dl>
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.column}>
          <dl>
            <dt><Text variant="small" className={styles.label}>Subscription Id</Text></dt>
            <dd><Text variant="small">{props.subscription.id}</Text></dd>
          </dl>
          <dl>
            <dt><Text variant="small" className={styles.label}>Status</Text></dt>
            <dd><Text variant="small">{props.subscription.status}</Text></dd>
          </dl>
          <dl>
            <dt><Text variant="small" className={styles.label}>Monthly Price</Text></dt>
            <dd><Text variant="small">{props.subscription.isAnnualCommitment ? formatCurrency(props.subscription.unitPrice / 12) : formatCurrency(props.subscription.unitPrice)}/each</Text></dd>
          </dl>
        </div>
        <div className={styles.column}>
          <dl>
            <dt><Text variant="small" className={styles.label}>Created On</Text></dt>
            <dd><Text variant="small">{formatDate(new Date(props.subscription.creationDate))}</Text></dd>
          </dl>
          {renewsOnComponent()}
          <dl>
            <dt><Text variant="small" className={styles.label}>Refundable Quantity&nbsp;<TooltipHost content="The quantity of licenses that have been added within the last 7 days and are available to be reduced immediately. Any licenses that were added more than 7 days ago cannot be immediately reduced and require the license reduction to be scheduled for the next renewal date."><FontIcon iconName="Info" /></TooltipHost></Text></dt>
            <dd><Text variant="small">{props.subscription.refundableQuantity}</Text></dd>
          </dl>
        </div>
        {props.subscription.catalogProductId &&
          <div className={styles.column}>
            <dl>
              <dt><Text variant="small" className={styles.label}>Product Id</Text></dt>
              <dd><Text variant="small">{props.subscription.catalogProductId}</Text></dd>
            </dl>
            <dl>
              <dt><Text variant="small" className={styles.label}>Sku Id</Text></dt>
              <dd><Text variant="small">{props.subscription.catalogSkuId}</Text></dd>
            </dl>
            <dl>
              <dt><Text variant="small" className={styles.label}>Availability Id</Text></dt>
              <dd><Text variant="small">{props.subscription.catalogAvailabilityId}</Text></dd>
            </dl>
          </div>
        }
        {!props.subscription.catalogProductId &&
          <div className={styles.column}>
            <dl>
              <dt><Text variant="small" className={styles.label}>Offer Id</Text></dt>
              <dd><Text variant="small">{props.subscription.offerId}</Text></dd>
            </dl>
          </div>
        }
      </div>
      {props.subscription.nextTermInstruction &&
        <div className={styles.lowerContainer}>
          <div className={styles.column}>
            <dl>
              <dt><Text variant="small" className={styles.label}>Updates at Next Renewal<FontIcon className={styles.warningIcon} iconName="WorkItemAlert" /></Text></dt>
              {props.subscription.nextTermInstruction.customerTermEndDate && <dd><Text variant="small">Term end date will be {formatDate(props.subscription.nextTermInstruction.customerTermEndDate)}</Text></dd>}
              {props.subscription.nextTermInstruction.quantity !== props.subscription.quantity && <dd><Text variant="small">Quantity will be reduced to {props.subscription.nextTermInstruction.quantity}</Text></dd>}
              {props.subscription.nextTermInstruction.productTerm && props.subscription.nextTermInstruction.productTerm.productId !== props.subscription.catalogProductId && <dd><Text variant="small">Product will be updated to {props.subscription.nextTermInstruction.productTerm.productId}</Text></dd>}
              {props.subscription.nextTermInstruction.productTerm && props.subscription.nextTermInstruction.productTerm.skuId !== props.subscription.catalogSkuId && <dd><Text variant="small">Sku will be updated to {props.subscription.nextTermInstruction.productTerm.skuId}</Text></dd>}
              {props.subscription.nextTermInstruction.productTerm && props.subscription.nextTermInstruction.productTerm.availabilityId !== props.subscription.catalogAvailabilityId && <dd><Text variant="small">Availability will be updated to {props.subscription.nextTermInstruction.productTerm.availabilityId}</Text></dd>}
              {props.subscription.nextTermInstruction.productTerm && props.subscription.nextTermInstruction.productTerm.termDuration !== props.subscription.termDuration && <dd><Text variant="small">Term will be updated to {props.subscription.nextTermInstruction.productTerm.termDuration}</Text></dd>}
            </dl>
          </div>
        </div>
      }
    </div>
  );
}