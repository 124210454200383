import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getUserById, getUsers, updateUser, createUser} from './calls';
import {IUsersQuery, IUserUpdateRequest, IUserCreateRequest} from './models';
import {onErrorHelper, onMutateHelper, onSettledHelper} from '../../hooks';
import {AxiosError} from "axios";

export const useUsers = (tenantId: string, query: IUsersQuery) => useQuery(['USERS', tenantId, query], () => getUsers(tenantId, query));

export const useUserById = (userId: string, tenantId: string) => useQuery(['USER', userId, tenantId], () => getUserById(userId, tenantId));

export const useUpdateUser = () => {
    const qc = useQueryClient();

    return useMutation(updateUser, {
        onMutate: (variables: IUserUpdateRequest) => onMutateHelper(qc, variables, ['USER'], 'update'),
        onError: (err: AxiosError, variables: IUserUpdateRequest, context) => onErrorHelper(qc, err, context, ['USER']),
        // onSettled: () => onSettledHelper(qc, ['USER']),
    });
}

export const useCreateUser = () => {
    const qc = useQueryClient();

    return useMutation(createUser, {
        onMutate: (variables: IUserCreateRequest) => onMutateHelper(qc, variables, ['USER'], 'create'),
        onError: (err: AxiosError, variables: IUserCreateRequest, context) => onErrorHelper(qc, err, context, ['USER']),
        // onSettled: () => onSettledHelper(qc, ['USER']),
    });
}