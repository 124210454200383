import React from 'react';
import { ISubscription } from '../../api/resources/subscriptions';
import { FontIcon, mergeStyleSets, Link, Text, getTheme, TooltipHost } from '@fluentui/react';
import BootstrapTable from 'react-bootstrap-table-next';
import { SubscriptionExpand } from './SubscriptionExpand';
import { Badge } from 'react-bootstrap';
import { SubscriptionQuantityIncrementer } from './SubscriptionQuantityIncrementer';
import { useNavigate } from 'react-router-dom';
import { ICustomer } from '../../api/resources/customers';
import { getIconName } from '../../services/ProductIconService';
import { TenantContext } from '../core/context/TenantContextProvider';
import { daysFromNow, formatExpirationMessage } from "../../services/DateFormatService";


export interface ILicenseSubscriptionsState {
  customer: ICustomer
  subscriptions?: ISubscription[]
}

const theme = getTheme();
const styles = {
  ...mergeStyleSets({
    table: {
      fontWeight: 'normal',
      tableLayout: 'initial !important',
      borderCollapse: 'separate !important',
      borderColor: 'transparent !important'
    },
    tableHeader: {
      color: '#6c757d'
    },
    rows: {
      boxShadow: `0 0 2px ${theme.palette.neutralSecondaryAlt}`,
      verticalAlign: 'middle'
    },
    mutedText: {
      color: theme.palette.neutralTertiary
    },
    offerNameContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'stretch'
    },
    productImage: {
      marginRight: '1rem',
      color: theme.palette.neutralSecondary
    },
    icon: {
      cursor: 'pointer',
      transition: 'all 0.2s',
      userSelect: 'none'
    },
    iconExpanded: {
      cursor: 'pointer',
      transition: 'all 0.2s',
      transform: 'rotate(90deg)',
      color: theme.palette.accent,
      fontWeight: 'bold',
      userSelect: 'none'
    },
    extendedPanel: {
      padding: '0px !important',
      backgroundColor: "#fafafa !important"
    },
    warningIcon: {
      marginLeft: '0.5rem',
      color: "#ff8800"
    }
  })
}

export const SubscriptionsList: React.FC<ILicenseSubscriptionsState> = (props) => {

  const tenantContext = React.useContext(TenantContext);

  const navigate = useNavigate();
  const goToSubscriptionDetailPage = (subscriptionId: string) => {
    const subscriptionDetailsPath = tenantContext!.isPartnerUser ? `/customers/${props.customer.tenantId}/subscriptions/${subscriptionId}` : `/subscriptions/${subscriptionId}`;
    navigate(subscriptionDetailsPath);
  };

  const subscriptionNameColumn = (cell: any, row: ISubscription) => {
    const daysTillExpiration = daysFromNow(new Date(row.commitmentEndDate));
    const isAnnualTerm = row.termDuration === "P1Y";

    return (
      <div className={styles.offerNameContainer}>
        <Text className={styles.productImage} variant='xxLarge'><FontIcon iconName={getIconName(row.offerName, false)} /></Text>
        <div>
          {row.status !== 'Active' && <Text block nowrap className={styles.mutedText}><Badge bg="secondary">{row.status}</Badge>&nbsp;{row.offerName}</Text>}
          {
            row.status === 'Active' &&
            <Text block nowrap>
              <Link onClick={() => goToSubscriptionDetailPage(row.id)}>{row.offerName}</Link>
              {
                row.nextTermInstruction &&
                <TooltipHost content="Changes are scheduled to occur at next renewal. See subscription details for more information.">
                  <FontIcon className={styles.warningIcon} iconName="WorkItemAlert" />
                </TooltipHost>
              }
              {
                isAnnualTerm && daysTillExpiration <= 60 &&
                <TooltipHost content={formatExpirationMessage(daysTillExpiration)}>
                  <FontIcon className={styles.warningIcon} iconName="info" />
                </TooltipHost>
              }
            </Text>
          }
        </div>
      </div>
    )
  };

  const subscriptionQuantityColumn = (cell: any, row: ISubscription) => row.status === 'Active' ? <SubscriptionQuantityIncrementer key={row.id} subscription={row} tenantId={props.customer.tenantId} /> : <span className={styles.mutedText}>{row.quantity}</span>
  const columns = [{
    dataField: "offerName",
    text: "",
    sort: true,
    align: 'left',
    headerAlign: 'left',
    formatter: subscriptionNameColumn
  }, {
    dataField: "quantity",
    text: "Total Licenses",
    sort: true,
    align: 'center',
    headerStyle: { fontWeight: 'normal' },
    formatter: subscriptionQuantityColumn
  }]

  const subscriptionExpansion = {
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    className: styles.extendedPanel,
    expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => (<span></span>),
    expandColumnRenderer: ({ expanded }: { expanded: boolean }) => (<FontIcon aria-label="Toggle subscription details" iconName="ChevronRight" className={expanded ? styles.iconExpanded : styles.icon}></FontIcon>),
    renderer: (subscription: ISubscription) => <SubscriptionExpand subscription={subscription} />
  }

  return (
    <BootstrapTable
      keyField="id"
      classes={styles.table}
      headerWrapperClasses={styles.tableHeader}
      headerClasses={styles.tableHeader}
      rowClasses={styles.rows}
      bordered={false}
      data={props.subscriptions ?? []}
      columns={columns}
      expandRow={subscriptionExpansion}
    />
  );
}