import React from 'react';
import {ContextualMenu, ContextualMenuItemType, DirectionalHint, IContextualMenuItem, IImageProps, Image, ImageFit, Persona, PersonaSize, PrimaryButton, Separator, Text} from "@fluentui/react";
import {FontWeights, getTheme, mergeStyleSets} from '@fluentui/react/lib/Styling';
import logoImage from "../../images/logo.png";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import { TenantContext } from './context/TenantContextProvider';
import { EndSessionRequest } from '@azure/msal-browser';

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        header: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.white,
            color: theme.palette.neutralDark
        },
        headerItemsContainer: {
            display: 'flex',
            alignItems: 'center',
            width: '88%'
        },
        headerLogo: {
            justifySelf: 'start',
            paddingTop: '.5rem',
            paddingBottom: '.5rem',
            paddingRight: '.5rem'
        },
        separator: {
            marginLeft: '.5rem',
            marginRight: '.5rem'
        },
        productName: {
            fontWeight: FontWeights.semibold,
            color: theme.palette.neutralSecondary
        },
        authContainer: {
            marginLeft: 'auto'
        },
        authItems: {
            display: 'flex'
        },
        partnerCenterButton: {
            marginRight: '2rem'
        },
        persona: {
            cursor: 'pointer',
            userSelect: 'none'
        },
        notSignedInText: {
            color: theme.palette.neutralTertiary
        },
    }),
};

const headerLogo: Partial<IImageProps> = {
    src: logoImage,
    imageFit: ImageFit.contain,
    width: 72
}

const notAuthenticatedImage: Partial<IImageProps> = {
    src: require('../../images/default-avatar.png'),
    imageFit: ImageFit.contain,
    width: 32,
    height: 32
}

export const Header: React.FC = () => {
    
    const {accounts, instance} = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const personaRef = React.useRef(null);
    const [isAuthMenuOpen, setIsAuthMenuOpen] = React.useState<boolean>(false);

    const tenantContext = React.useContext(TenantContext);

    const signOut = () => {
        const request: EndSessionRequest = {
            postLogoutRedirectUri: window.location.origin
        };
        instance.logoutRedirect(request);
    }

    const contextMenu: IContextualMenuItem[] = [{
        key: 'separator',
        itemType: ContextualMenuItemType.Divider
    }, {
        key: 'signout',
        text: 'Sign out',
        onClick: () => signOut()
    }];

    return (
        <header className={styles.header}>
            <div className={styles.headerItemsContainer}>
                <div className={styles.headerLogo}>
                    <Image {...headerLogo} />
                </div>
                <Separator className={styles.separator} vertical />
                <Text variant='mediumPlus' className={styles.productName}>Customer Portal</Text>
                <div className={styles.authContainer}>
                    { isAuthenticated && 
                    
                    <div className={styles.authItems}>
                        { tenantContext!.isPartnerUser && <PrimaryButton className={styles.partnerCenterButton} href='https://partnercenter.microsoft.com/dashboard' target='_blank'>Partner Center</PrimaryButton> }
                        <Persona 
                            ref={personaRef}
                            className={styles.persona}
                            text={accounts[0].name}
                            secondaryText={tenantContext!.isPartnerUser ? tenantContext!.partnerCompanyName : tenantContext!.companyName}
                            size={PersonaSize.size32} 
                            imageAlt={`${accounts[0].username}`}
                            onClick={() => setIsAuthMenuOpen(!isAuthMenuOpen)} />

                        <ContextualMenu
                            directionalHint={DirectionalHint.bottomRightEdge}
                            gapSpace={9}
                            items={contextMenu}
                            hidden={!isAuthMenuOpen}
                            target={personaRef}
                            onDismiss={() => setIsAuthMenuOpen(false)} />
                    </div>
                    }
                </div>
            </div>
        </header>
    );
}