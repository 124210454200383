import { ContextualMenu, ContextualMenuItemType, DefaultButton, DirectionalHint, IconButton, IContextualMenuProps, mergeStyleSets } from "@fluentui/react";
import React from "react";
import { ICustomer } from "../../api/resources/customers";
import { IUser } from "../../api/resources/users";
import { BlockUserSignInModal } from "./modals/BlockUserSignInModal";
import { ManageLicensesModal } from "./modals/ManageLicensesModal";
import { PasswordResetModal } from "./modals/PasswordResetModal";

export interface IUserActionButtonProps {
    user: IUser,
    customer: ICustomer
    menuDirection?: DirectionalHint
    iconOnly?: boolean
}
const styles = {
    ...mergeStyleSets({
        container: {
            padding: '1rem'
        },
        withShadow: {
            boxShadow: '0 0 2px #DEDEDE'
        },
        textCenter: {
            textAlign: 'center'
        },
        icon: {
            color: '#117492'
        },
        errorText: {
            color: '#ff0033'
        }
    })
}

export const UserActionButton: React.FC<IUserActionButtonProps> = (props) => {

    const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = React.useState<boolean>(false);

    const [isBlockUserModalOpen, setIsBlockUserModalOpen] = React.useState<boolean>(false);
    const [isLicenseAssignmentModalOpen, setIsLicenseAssignmentModalOpen] = React.useState<boolean>(false);

    const userActions: IContextualMenuProps = {
        items: [{
            key: 'manageLicenses',
            text: 'Manage licenses',
            iconProps: { iconName: 'AddToShoppingList', className: styles.icon },
            onClick: () => setIsLicenseAssignmentModalOpen(true)
        }, {
            key: 'divider',
            itemType: ContextualMenuItemType.Divider
        }, {
            key: 'block',
            text: 'Block',
            iconProps: { iconName: 'BlockContact', className: styles.errorText },
            onClick: () => setIsBlockUserModalOpen(true)
        }]
    };

    if(props.menuDirection) {
        userActions.directionalHint = props.menuDirection;
    }

    return(
        <div>
            <PasswordResetModal
                hidden={!isPasswordResetModalOpen}
                customer={props.customer}
                user={props.user}
                onDismiss={() => setIsPasswordResetModalOpen(false)} />

            <ManageLicensesModal
                hidden={!isLicenseAssignmentModalOpen}
                customer={props.customer}
                user={props.user}
                onDismiss={() => setIsLicenseAssignmentModalOpen(false)} />

            <BlockUserSignInModal
                hidden={!isBlockUserModalOpen}
                customer={props.customer}
                user={props.user}
                onDismiss={() => setIsBlockUserModalOpen(false)} />

            { props.iconOnly && <IconButton iconProps={{ iconName: 'Settings' }} menuProps={userActions} /> }
            { !props.iconOnly && <DefaultButton menuProps={userActions}>Actions</DefaultButton> }
        </div>
    );
}