import theme from "./Theme";
import './App.css';
import {PageLayout} from "./components/PageLayout";
import {QueryClient, QueryClientProvider} from 'react-query';
import {useMsalAuthentication} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import { initializeIcons, ThemeProvider } from '@fluentui/react';

import { ReactQueryDevtools } from 'react-query/devtools';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            staleTime: 30000,
            // Disabling these to avoid issues with unexpected UI updates while users are working in views. Override these in individual
            // queries where refetching is desired.
            refetchInterval: 0,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
        },
        mutations: {
            retry: false,
        },
    },
});

initializeIcons();

function App() {
    useMsalAuthentication(InteractionType.None);

    return (
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <PageLayout />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
