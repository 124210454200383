import React from 'react';
import { mergeStyleSets, Link, Text } from '@fluentui/react';
import {ICustomer} from '../../api/resources/customers';
import {useNavigate} from 'react-router-dom';
import {CustomerQuickLinks} from './CustomerQuickLinks';
import BootstrapTable from 'react-bootstrap-table-next';

export interface ICustomerListProps {
    customers: ICustomer[]
    onSortChange: (field: string, order: string) => void
}

const styles = {
    ...mergeStyleSets({
        tableHeader: {
            paddingBottom: '15px',
            height: '50px',
            textAlign: 'center'
        },
        tableRow: {
            textAlign: 'left'
        },
        tableCell: {
            display: "flex",
            flexFlow: "wrap",
            justifyContent: "center"
        },
        quickLink: {
            paddingLeft: "1rem"
        },
        loading: {
            marginBottom: '1rem',
            fontStyle: 'italic',
            color: 'gray'
        },
        mutedText: {
            color: '#787878'
        },
        table: {
            fontWeight: 'normal',
            tableLayout: 'initial !important',
            borderCollapse: 'separate !important',
            borderColor: 'transparent !important'
        },
        rows: {
            boxShadow: '0 0 2px #DEDEDE',
            verticalAlign: 'middle'
        },
    })
}

export const CustomersList: React.FC<ICustomerListProps> = (props) => {
    const navigate = useNavigate();


    const goToCustomerPage = (tenantId: string) => {
        navigate(`/customers/${tenantId}/subscriptions`);
    }

    const customerNameColumn = (_cell: any, row: ICustomer) => {
        return (
            <div>
                <Text block><Link onClick={() => goToCustomerPage(row.tenantId)}>{row.companyName}</Link></Text>
                <Text block className={styles.mutedText}>{row.tenantId}</Text>
            </div>
        );
    }

    const quickLinksColumn = (_cell: any, row: ICustomer) => {
        return <CustomerQuickLinks customer={row} />
    }

    const columns = [{
        dataField: "companyName",
        text: "Company",
        sort: true,
        onSort: (field: string, order: string) => { props.onSortChange(field, order) },
        align: "left",
        headerAlign: "left",
        formatter: customerNameColumn
    }, {
        dataField: "id",
        text: 'Quick Links',
        align: 'center',
        headerAlign: 'center',
        formatter: quickLinksColumn
    }]

    return (
        <BootstrapTable
            keyField="tenantId"
            classes={styles.table}
            headerWrapperClasses={styles.tableHeader}
            rowClasses={styles.rows}
            bordered={false}
            data={props.customers}
            columns={columns}
        />
    );
}