import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useId } from "@fluentui/react-hooks";
import { Layer, LayerHost } from "@fluentui/react";
import {
  Header,
  SignInCard,
  Footer,
  ErrorCard,
  RequestInterceptor,
} from "./core";
import { BrowserRouter } from "react-router-dom";
import { MainRouter } from "../routing/MainRouter";
import { ErrorBoundary } from "react-error-boundary";
import { TenantContextProvider } from "./core/context/TenantContextProvider";

const styles = {
  ...mergeStyleSets({
    pageContent: {
      display: "flex",
      justifyContent: "center",
    },
    mainContent: {
      position: "absolute",
      top: "15%",
      maxWidth: "86%",
      minWidth: "50%",
    },
  }),
};

export const PageLayout: React.FC = () => {
  const layerHostId = useId("layerHost");

  return (
    <div>
      <LayerHost id={layerHostId} className={styles.pageContent}></LayerHost>
      <AuthenticatedTemplate>
        <RequestInterceptor>
          <TenantContextProvider layerHostId={layerHostId}>
            <Layer>
              <Header />
            </Layer>
            <Layer hostId={layerHostId} className={styles.mainContent}>
              <ErrorBoundary FallbackComponent={ErrorCard}>
                <BrowserRouter>
                  <MainRouter />
                </BrowserRouter>
              </ErrorBoundary>
              <Footer />
            </Layer>
          </TenantContextProvider>
        </RequestInterceptor>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Layer>
          <Header />
        </Layer>
        <Layer hostId={layerHostId} className={styles.mainContent}>
          <ErrorBoundary FallbackComponent={ErrorCard}>
            <BrowserRouter>
              <RequestInterceptor>
                <SignInCard />
              </RequestInterceptor>
            </BrowserRouter>
          </ErrorBoundary>
        </Layer>
      </UnauthenticatedTemplate>
    </div>
  );
};
