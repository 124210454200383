import { FontIcon, mergeStyleSets, Text } from "@fluentui/react";
import { AxiosError } from "axios";
import React from "react";
import { getFormattedErrorMessage } from "./services/ErrorFormattingService";

const styles = {
    ...mergeStyleSets({
        successText: {
            color: "#98c652"
        },
        textCenter: {
            textAlign: 'center'
        }
    })
}

export interface ISuccessMessageProps {
    message: string,
    iconName?: string
    children?: React.ReactNode;
}

export const SuccessMessage: React.FC<ISuccessMessageProps> = (props) => {
    return (
        <div className={styles.textCenter}>
            <div><Text block variant='xxLargePlus' className={styles.successText}><FontIcon iconName={props.iconName ? props.iconName : "CheckMark"} /></Text></div>
            <div><Text block>{props.message}</Text></div>
            <br />
            { props.children && <div>{props.children}</div> }
        </div>
    );
}