import { Breadcrumb, getTheme, IBreadcrumbItem, Text, mergeStyleSets } from "@fluentui/react";
import React from "react";
import { BreadcrumbItem } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ICustomer } from "../../api/resources/customers";
import { CustomerQuickLinks } from "../customers";
import { TenantContext } from "./context/TenantContextProvider";

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        cardContent: {
            backgroundColor: theme.palette.white,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            paddingLeft: '5rem',
            paddingRight: '5rem'
        },
        noBreadcrumbs: {
            marginTop: '5rem'
        },
        breadcrumbs: {
            marginBottom: '2rem',
        },
        breadcrumbItem: {
            fontSize: '0.8rem',
            color: theme.palette.neutralSecondary,
            outline: 'transparent',
            textDecoration: 'none',
            position: 'relative',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '0px 8px',
            lineHeigh: '1.2rem',
            ':hover': {
                textDecoration: 'none',
                backgroundColor: theme.palette.neutralLight,
                color: theme.palette.neutralSecondary,
                cursor: 'pointer'
            }
        },
        tabs: {
            marginBottom: '2rem'
        },
        cardSubHeader: {
            marginBottom: '1rem',
            color: theme.palette.neutralTertiary
        },
        quickLinks: {
            display: "flex",
            flexFlow: "wrap",
            justifyContent: "center",
            paddingBottom: '1rem'
        },
        loading: {
            marginTop: '8rem',
            marginBottom: '8rem',
            fontStyle: 'italic',
            fontSize: '14',
            color: theme.palette.white
        }
    }),
};

export interface IOrderedBreadcrumbItem extends IBreadcrumbItem {
    position: number;
}

export interface IPageHeaderProps {
    pageTitle: string
    breadcrumbs?: IOrderedBreadcrumbItem[]
    customer?: ICustomer
}

export const PageHeader: React.FC<IPageHeaderProps> = (props) => {

    const tenantContext = React.useContext(TenantContext);
    const location = useLocation();

    const breadcrumbs: IBreadcrumbItem[] = [];
    
    if(tenantContext!.isPartnerUser) {
        breadcrumbs.push({
            key: 'customers',
            text: 'Customers',
            href: "/customers"
        })
    } else {
        breadcrumbs.push({
            key: tenantContext!.customerTenantId,
            text: tenantContext!.companyName,
            href: `/subscriptions`
        })
    }

    if(props.breadcrumbs && props.breadcrumbs.length > 0) {
        props.breadcrumbs
            .sort((a, b) => a.position - b.position)
            .forEach(breadcrumb => breadcrumbs.push(breadcrumb));
    }

    const onRenderItem = (props: IBreadcrumbItem | undefined) =>{
        return (
            <a className={styles.breadcrumbItem} href={props?.href} onClick={props?.onClick}>{props?.text}</a>
        );
    }

    return (
        <div>
            { tenantContext!.isPartnerUser && location.pathname !== "/customers" && <Breadcrumb className={styles.breadcrumbs} items={breadcrumbs} onRenderItem={(props) => onRenderItem(props)}  />}
            { !tenantContext!.isPartnerUser && breadcrumbs && breadcrumbs.length > 1 && <Breadcrumb className={styles.breadcrumbs} items={breadcrumbs} /> }
            <Text block className={breadcrumbs && breadcrumbs.length > 1 ? '' : styles.noBreadcrumbs} variant="xLarge">{props.pageTitle}</Text>
            <Text block variant="small" className={styles.cardSubHeader} >{tenantContext?.partnerCompanyName}</Text>
            { props.customer && 
            <div className={styles.quickLinks}>
                { props.customer && <CustomerQuickLinks customer={props.customer}/> }
            </div>
            }
        </div>
    );
}