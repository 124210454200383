const defaultFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

export const formatCurrency = (value: number, format?: Intl.NumberFormat) => {
    const formatter = format ?? defaultFormatter;
    const formattedValue = formatter.format(value);
    if(formattedValue === '$0.00') {
        return "Contact "+process.env.REACT_APP_COMPANY_NAME+" for pricing";
    } else {
        return formattedValue;
    }
}