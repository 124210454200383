import React, { useEffect } from 'react';
import {CommandBar, getTheme, mergeStyleSets, SearchBox, Spinner, SpinnerSize, Text} from "@fluentui/react";
import {IProduct, IProductsQuery, useProducts} from "../../../api/resources/products";
import {ProductsList} from "../ProductsList";
import { FluentPaginator } from '../../core/FluentPaginator';
import { ICustomer } from '../../../api/resources/customers';
import { ISubscription } from '../../../api/resources/subscriptions';

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        pageHead: {
            marginTop: '2rem',
            marginBottom: '.5rem',
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'space-between'
        },
        titleContainer: {

        },
        tableTitle: {
            textAlign: 'left'
        },
        toolbarContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        toolbar: {

        },
    }),
};

export interface IProductsTabProps {
    customer: ICustomer
}

export const ProductsTab: React.FC<IProductsTabProps> = (props) => {

    const pageSizeOptions = [10, 25, 50];
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [pageSize, setPageSize] = React.useState<number>(pageSizeOptions[0]);
    const [search, setSearch] = React.useState<string>("");

    const productsQuery = useProducts({ page: currentPage, pageSize, filter: search });

    const getProductPage = (page: number) => {
        setCurrentPage(page);
        productsQuery.refetch();
    }

    return (
        <div>
            <div>  
                <div className={styles.pageHead}>
                    <div className={styles.titleContainer}>
                        <Text variant="large" block className={styles.tableTitle}>
                            Products
                        </Text>
                    </div>
                    <div className={styles.toolbarContainer}>
                        <SearchBox placeholder="Search" 
                            onClear={() => setSearch("")}
                            onEscape={() => setSearch("")}
                            onChange={(_event?: React.ChangeEvent<HTMLInputElement> | undefined, filter?: string | undefined) => setSearch(filter ?? "")}
                            onSearch={(_event?: React.ChangeEvent<HTMLInputElement> | undefined, filter?: string | undefined) => setSearch(filter ?? "")} />
                    </div>
                </div>
                { productsQuery.isLoading || productsQuery.isRefetching && <Spinner label="Getting products..." size={SpinnerSize.large} />}
                { !productsQuery.isLoading && !productsQuery.isRefetching && <ProductsList products={productsQuery.data ? productsQuery.data.products : []} customer={props.customer} /> }
                <FluentPaginator currentPage={currentPage} itemsPerPage={pageSize} itemsPerPageOptions={pageSizeOptions} onPageChange={(page) => getProductPage(page)} totalItems={productsQuery.data ? productsQuery.data.totalProducts : 0} onItemsPerPageChange={(itemsPerPage) => setPageSize(itemsPerPage)} />
            </div>
        </div>
    );
}