import React from 'react';
import {useParams} from 'react-router-dom';
import {Card} from 'react-bootstrap';
import {getTheme, mergeStyleSets, Spinner, SpinnerSize, Pivot, PivotItem, IBreadcrumbItem} from '@fluentui/react';
import {useCustomerById} from '../../../api/resources/customers';
import {UsersTab} from '../../users';
import {ProductsTab} from '../../products';
import {CustomerDetailsTab} from "./CustomerDetailsTab";
import { IOrderedBreadcrumbItem, PageHeader } from '../../core/PageHeader';
import { SubscriptionsTab } from '../../subscriptions/views/SubscriptionsTab';
import { TenantContext } from '../../core/context/TenantContextProvider';


const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        cardContent: {
            backgroundColor: theme.palette.white,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            paddingLeft: '5rem',
            paddingRight: '5rem'
        },
        tabs: {
            marginBottom: '2rem'
        },
        loading: {
            marginTop: '8rem',
            marginBottom: '8rem',
            fontStyle: 'italic',
            fontSize: '14',
            color: 'white'
        }
    }),
};

export interface ICustomerCardProps {
    tenantId?: string
    tab?: string
}

export const CustomerCard: React.FC<ICustomerCardProps> = (props) => {

    const tenantContext = React.useContext(TenantContext);
    const { id } = useParams();
    const customerTenantId = id ?? tenantContext!.customerTenantId;
    const customerQuery = useCustomerById(customerTenantId);

    const breadcrumbs: IOrderedBreadcrumbItem[] = [];
    
    if(customerQuery.data) {
        breadcrumbs.push({
            key: customerQuery.data.tenantId,
            text: customerQuery.data.companyName,
            isCurrentItem: true,
            position: 1
        });
    }

    if(customerTenantId !== customerQuery.data?.tenantId)
        customerQuery.refetch();

    const updateUrl = (selectedItem?: PivotItem) => window.history.replaceState(null, "", tenantContext!.isPartnerUser ? `/customers/${customerQuery.data?.tenantId}/${selectedItem?.props.itemKey}` : `/${selectedItem?.props.itemKey}`);

    return (
        <Card className={styles.cardContent}>
            { (customerQuery.isLoading || customerQuery.isRefetching) && <Spinner className={styles.loading} size={SpinnerSize.large} label="Getting customer info..." /> }
            { !customerQuery.isLoading && !customerQuery.isRefetching && customerQuery.data &&
            <div>
                <PageHeader customer={customerQuery.data} pageTitle={customerQuery.data.companyName} breadcrumbs={breadcrumbs} />
                <Pivot aria-label="Customer page tabs" className={styles.tabs} defaultSelectedKey={props.tab ?? "subscriptions"} onLinkClick={(item?: PivotItem | undefined, ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => updateUrl(item)} >
                    <PivotItem itemKey="subscriptions" headerText="License Subscriptions"><SubscriptionsTab customer={customerQuery.data} /></PivotItem>
                    <PivotItem itemKey="users" headerText="Users"><UsersTab customer={customerQuery.data}></UsersTab></PivotItem>
                    <PivotItem itemKey="profile" headerText="Company Profile"><CustomerDetailsTab customer={customerQuery.data}></CustomerDetailsTab></PivotItem>
                    <PivotItem itemKey="store" headerText="Store"><ProductsTab customer={customerQuery.data} /></PivotItem>
                </Pivot>
            </div>
            }
        </Card>
    );
}