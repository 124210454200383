import { DirectionalHint, FontIcon, IIconProps, Label, mergeStyleSets, Spinner, SpinnerSize, Text, TextField, Toggle, TooltipHost } from "@fluentui/react"
import React from "react"
import { ICustomer } from "../../../api/resources/customers"
import { IUser, IUserUpdateRequest, useUpdateUser } from "../../../api/resources/users"
import { ActionDialog } from "../../core/ActionDialog"
import { ErrorMessage } from "../../core/ErrorMessage"
import { SuccessMessage } from "../../core/SuccessMessage"
import { validatePassword } from "../services/PasswordValidationService"

export interface IPasswordResetModalProps {
    hidden: boolean,
    customer: ICustomer
    user: IUser,
    onDismiss: () => void
}

const styles = {
    ...mergeStyleSets({
        container: {
            padding: '1rem',
            textAlign: 'center'
        },
        formContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        formColumn: {
            flex: 1
        }
    })
}

export const PasswordResetModal: React.FC<IPasswordResetModalProps> = (props) => {

    const updateUserCommand = useUpdateUser();

    const [newPassword, setNewpassword] = React.useState<string>("");
    const [generatePassword, setGeneratePassword] = React.useState<boolean>(true);
    const [forcePasswordReset, setForcePasswordReset] = React.useState<boolean>(true);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState<string>("");
    const [hideForm, setHideForm] = React.useState<boolean>(false);
    const [isConfirmationButtonVisible, setIsConfirmationButtonVisible] = React.useState<boolean>(true);
    const [cancelButtonText, setCancelButtonText] = React.useState<string>('Cancel');
    const [cancelIcon, setCancelIcon] = React.useState<IIconProps>({ iconName: "Cancel"});

    const generatedPasswordLabel = (
        <Label>Generate password <TooltipHost directionalHint={DirectionalHint.bottomCenter} content="If enabled, the generated password will be shown after the new user is created"><FontIcon iconName="info" /></TooltipHost></Label>
    );

    const requireResetLabel = (
        <Label>Require reset <TooltipHost directionalHint={DirectionalHint.bottomCenter} content="If enabled, the user will be required to reset their password the next time they sign in"><FontIcon iconName="info" /></TooltipHost></Label>
    );

    const resetPassword = () => {
        if(!generatePassword) {
            let passwordValidationError = validatePassword(newPassword);
            setPasswordErrorMessage(passwordValidationError ?? "");
            if(passwordValidationError) {
                return;
            }
        }
        setHideForm(true);
        const request: IUserUpdateRequest = {
            tenantId: props.customer.tenantId,
            userId: props.user.id,
            forcePasswordReset,
            newPassword
        };
        updateUserCommand.mutate(request);
        setIsConfirmationButtonVisible(false);
        setCancelButtonText("Close");
        setCancelIcon({ iconName: "CheckMark" });
    }

    const close = () => {
        setHideForm(false);
        props.onDismiss();
        updateUserCommand.reset();
        setNewpassword("");
        setGeneratePassword(true);
        setForcePasswordReset(true);
        setPasswordErrorMessage("");
        setCancelButtonText("Cancel");
        setCancelIcon({ iconName: "Cancel" });
        setIsConfirmationButtonVisible(true);
    }
    
    if(!props.user) {
        return <></>;
    }

    return (
      <ActionDialog
        hidden={props.hidden}
        action={() => resetPassword()}
        onDismiss={() => close()}
        title={`${props.user.displayName} password reset`}
        confirmText="Reset"
        errorComponent={
          <ErrorMessage
            message={`Sorry, we couldn't change ${props.user.displayName}'s password`}
            axiosError={updateUserCommand.error ?? undefined}
          />
        }
        successComponent={
          <SuccessMessage
            message={`${props.user.displayName}'s password has been reset successfully`}
          >
            {generatePassword && (
              <div>
                <Text block>Password:</Text>
                <Text block>
                  <code>{updateUserCommand.data?.newPassword}</code>
                </Text>
              </div>
            )}
          </SuccessMessage>
        }
        isLoading={updateUserCommand.isLoading}
        loadingMessage={`Resetting ${props.user.displayName}'s password...`}
        isError={updateUserCommand.isError}
        isSuccess={updateUserCommand.isSuccess}
      >
        <div className={styles.container}>
          <div className={styles.formContainer}>
            <div className={styles.formColumn}>
              <Toggle
                label={generatedPasswordLabel}
                defaultChecked={generatePassword}
                onChange={(
                  _event: React.MouseEvent<HTMLElement>,
                  checked?: boolean
                ) => setGeneratePassword(checked ?? false)}
              />
            </div>
            <div className={styles.formColumn}>
              <Toggle
                label={requireResetLabel}
                defaultChecked={forcePasswordReset}
                onChange={(
                  _event: React.MouseEvent<HTMLElement>,
                  checked?: boolean
                ) => setForcePasswordReset(checked ?? false)}
              />
            </div>
          </div>
          {!generatePassword && (
            <TextField
              errorMessage={passwordErrorMessage}
              defaultValue={newPassword}
              tabIndex={8}
              type="password"
              canRevealPassword
              revealPasswordAriaLabel="Show password"
              label="New password"
              validateOnFocusOut
              onChange={(
                _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => setNewpassword(newValue ?? "")}
            />
          )}
        </div>
      </ActionDialog>
    );
}