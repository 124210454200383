import React from 'react';
import {Card} from "react-bootstrap";
import {getTheme, mergeStyleSets, SearchBox, Text, Spinner, SpinnerSize, IBreadcrumbItem} from "@fluentui/react";
import {CustomersList} from "../CustomersList";
import {useCustomers} from "../../../api/resources/customers";
import { PageHeader } from '../../core/PageHeader';
import { FluentPaginator } from '../../core/FluentPaginator';

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        cardContent: {
            backgroundColor: theme.palette.white,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            paddingLeft: '5rem',
            paddingRight: '5rem',
            paddingBottom: '2rem',
            marginBottom: '2rem'
        },
        loading: {
            marginTop: "8rem",
            marginBottom: "8rem",
            fontStyle: "italic",
            fontSize: "14",
            color: "white",
        },
        pageHead: {
            marginTop: '2rem',
            marginBottom: '.5rem',
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'end'
        },
        titleContainer: {

        },
        tableTitle: {
            textAlign: 'left'
        },
        toolbarContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        toolbar: {

        },
    }),
};

export const CustomersCard: React.FC = () => {

    const pageSizeOptions = [10, 25, 50];

    const [page, setPage] = React.useState<number>(1);
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [filter, setFilter] = React.useState<string>("");
    const [orderBy, setOrderBy] = React.useState('CompanyName');
    const [asc, setAsc] = React.useState<boolean>(true);

    const customersQuery = useCustomers({ page, pageSize, filter, asc, orderBy });

    const handleSort = (field: string, order: string) => {

        if(orderBy !== field) {
            setOrderBy(field);
        }

        if(asc !== (order === 'asc')) {
            setAsc(order === 'asc');
        }
    }

    const getProductPage = (page: number) => {
        setPage(page);
    };

    return (
        <Card className={styles.cardContent}>
                <div>
                    <PageHeader pageTitle='Customers' />
                    <div>
                        <div className={styles.pageHead}>
                            <div className={styles.toolbarContainer}>
                                <SearchBox placeholder="Search" 
                                    onClear={() => setFilter("")}
                                    onEscape={() => setFilter("")}
                                    onChange={(_event?: React.ChangeEvent<HTMLInputElement> | undefined, filter?: string | undefined) => setFilter(filter ?? "")}
                                    onSearch={(_event?: React.ChangeEvent<HTMLInputElement> | undefined, filter?: string | undefined) => setFilter(filter ?? "")} />
                            </div>
                        </div>
                        { (customersQuery.isLoading || customersQuery.isRefetching) && <Spinner className={styles.loading} size={SpinnerSize.large} label="Getting customers..." /> }
                        { !customersQuery.isLoading && !customersQuery.isRefetching && <CustomersList customers={customersQuery.data ? customersQuery.data.customers : []} onSortChange={handleSort} /> }
                        <FluentPaginator currentPage={page} itemsPerPage={pageSize} itemsPerPageOptions={pageSizeOptions} onPageChange={(page) => getProductPage(page)} totalItems={customersQuery.data ? customersQuery.data.totalCustomers : 0} onItemsPerPageChange={(itemsPerPage) => setPageSize(itemsPerPage)} />
                    </div>
                </div>
        </Card>
    );

}