export const validatePassword = (value: string) => {
    let isPasswordValid = true;
    if(!value) {
        isPasswordValid = false;
        return "Password is required";
    }
    if(value.length < 8) {
        isPasswordValid = false;
        return "Password must be at least 8 characters";
    }
    if(value.search(/[a-z]/) == -1) {
        isPasswordValid = false;
        return "Password must contain at least 1 lower case letter";
    }
    if(value.search(/[A-Z]/) == -1) {
        isPasswordValid = false;
        return "Password must contain at least 1 upper case character";
    }
    if(value.search(/\d/) == -1) {
        isPasswordValid = false;
        return "Password must contain at least 1 number";
    }
    if(value.search(/[^A-Za-z0-9]/) == -1) {
        isPasswordValid = false;
        return "Password must contain at least 1 special character";
    }
    if(isPasswordValid) {
        return "";
    }
}