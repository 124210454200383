import { PartialTheme } from "@fluentui/react";

const theme: PartialTheme = {
  palette: {
    themePrimary: '#117492',
    themeLighterAlt: '#f2f9fb',
    themeLighter: '#cce6ed',
    themeLight: '#a3d0de',
    themeTertiary: '#59a6bd',
    themeSecondary: '#24829f',
    themeDarkAlt: '#106883',
    themeDark: '#0d586e',
    themeDarker: '#0a4151',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
    accent: '#98C652'
  }
}

export default theme;