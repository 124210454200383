export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID ? process.env.REACT_APP_AUTH_CLIENT_ID : "",
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ? process.env.REACT_APP_AUTH_REDIRECT_URI : window.location.origin,
        postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_URI ? process.env.REACT_APP_AUTH_POST_LOGOUT_URI : `${window.location.origin}/logout`
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: process.env.REACT_APP_AUTH_SCOPES ? process.env.REACT_APP_AUTH_SCOPES.split(",") : []
};
