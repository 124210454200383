import React from 'react';
import {ActionButton, FontIcon, Link, mergeStyleSets, Spinner, SpinnerSize, Text, TooltipHost} from '@fluentui/react';
import {ICustomer, useAcceptAgreement} from '../../../api/resources/customers'
import { formatDate } from '../../../services/DateFormatService';
import { BaseDialog } from '../../core/BaseDialog';
import { AxiosError } from 'axios';
import { ErrorMessage } from '../../core/ErrorMessage';
import { useMsal } from '@azure/msal-react';

export interface ICustomerDetailsTabState {
    customer: ICustomer
}

const styles = {
    ...mergeStyleSets({
        tintedBackground: {
            backgroundColor: "#fafafa"
        },
        pageHead: {
            marginTop: '2rem',
            marginBottom: '.5rem',
            textAlign: 'left'
        },
        flexContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        container: { 
            display: 'flex',
            alignItems: 'stretch',
            padding: '1rem',
            marginTop: '2rem'
        },
        column: {
            paddingRight: '2rem',
            flex: 1,
            textAlign: 'left'
        },
        label: {
            color: '#787878'
        },
        warningText: {
            color: "#ff8800"
        },
        successText: {
            color: "#98c652"
        },
        errorText: {
            color: '#ff0033'
        },
        marginRight: {
            marginRight: '0.5rem'
        }
    }),
};

export const CustomerDetailsTab: React.FC<ICustomerDetailsTabState> = (props) => {

    const {accounts} = useMsal();
    const currentUser = accounts[0];

    const [agreementConfirmationDialogIsOpen, setAgreementConfirmationDialogIsOpen] = React.useState<boolean>(false);
    const [showAgreementAcceptanceSuccess, setShowAgreementAcceptanceSuccess] = React.useState<boolean>(false);
    const [showAgreementAcceptanceError, setShowAgreementAcceptanceError] = React.useState<boolean>(false);
    const [agreementAcceptanceError, setAgreementAcceptanceError] = React.useState<AxiosError | null>(null);
    const [isAgreementAcceptanceErrorDialogOpen, setIsAgreementAcceptanceErrorDialogOpen] = React.useState<boolean>(false);
    const acceptAgreementCommand = useAcceptAgreement();

    const resetErrorAndCloseDialog = () => {
        setShowAgreementAcceptanceError(false);
        setAgreementAcceptanceError(null);
        setIsAgreementAcceptanceErrorDialogOpen(false);
    }

    const acceptMicrosoftCustomerAgreement = () => {
        setAgreementConfirmationDialogIsOpen(false);
        const signerInfo = {
            signerEmail: currentUser!.username,
            signerFirstName: currentUser!.name!.includes(' ') ? currentUser!.name!.split(' ')[0] : currentUser!.name!,
            signerLastName: currentUser!.name!.includes(' ') ? currentUser!.name!.split(' ')[1] : '',
            tenantId: currentUser!.tenantId
        }

        acceptAgreementCommand.mutateAsync(signerInfo)
            .then(() => {
                setShowAgreementAcceptanceSuccess(true);
                setShowAgreementAcceptanceError(false);
                setTimeout(() => setShowAgreementAcceptanceSuccess(false), 3000);
            }, 
            (error) => {
                setShowAgreementAcceptanceError(true);
                setShowAgreementAcceptanceSuccess(false);
                setAgreementAcceptanceError(error);
            })
    };

    let tooltipText = `The Microsoft Customer Agreement is required to be signed before ${process.env.REACT_APP_COMPANY_NAME} can transact with Microsoft on the Customer's behalf.`;
    let contactName = '';
    let contactEmail = '';
    if(props.customer.billingContact) {
        contactName = `${props.customer.billingContact.firstName} ${props.customer.billingContact.lastName}`;
        contactEmail = props.customer.billingContact.email ? props.customer.billingContact.email.toLocaleLowerCase() : '';
    }

    return (
        <div>
            <BaseDialog
                hidden={!agreementConfirmationDialogIsOpen}
                onDismiss={() => setAgreementConfirmationDialogIsOpen(false)}
                additionalDialogButtons={[ { shouldRender: true, element: <ActionButton onClick={() => acceptMicrosoftCustomerAgreement()} iconProps={{ iconName: 'CheckMark'}}> Accept</ActionButton>  }]}
                title={props.customer.companyName}>
                <Text block>Do you accept the Microsoft Customer Agreement on behalf of {props.customer.companyName}?</Text>
                <br />
                <Text block><Link href={props.customer.microsoftCustomerAgreementUrl} target="_blank">View Agreement</Link></Text>
            </BaseDialog>

            <BaseDialog
                hidden={!isAgreementAcceptanceErrorDialogOpen}
                onDismiss={() => resetErrorAndCloseDialog()}
                title={props.customer.companyName}>
                    <ErrorMessage message={`Failed to accept the Microsoft Customer Agreement on behalf of ${props.customer.companyName}`} axiosError={agreementAcceptanceError!} />
                </BaseDialog>

            <div>
                <Text variant="large" block className={styles.pageHead}>
                    Company Profile
                </Text>
            </div>
            <div className={`${styles.container} ${styles.tintedBackground}`}>
                <div className={styles.column}>
                    <dl>
                        <dt><Text variant="small" className={styles.label}>Customer since</Text></dt>
                        <dd><Text variant="small">{formatDate(new Date(props.customer.dateAcquired))}</Text></dd>
                    </dl>
                    <dl>
                        <dt><Text variant="small" className={styles.label}>Account manager</Text></dt>
                        <dd><Text variant="small">{props.customer.accountManager}</Text></dd>
                    </dl>
                    <dl>
                        <dt><Text variant="small" className={styles.label}>Industry</Text></dt>
                        <dd><Text variant="small">{props.customer.industry}</Text></dd>
                    </dl>
                </div>
                <div className={styles.column}>
                    <dl>
                        <dt><Text variant="small" className={styles.label}>Primary contact</Text></dt>
                        <dd><Text variant="small">{contactName}</Text></dd>
                    </dl>
                    <dl>
                        <dt><Text variant="small" className={styles.label}>Primary contact email</Text></dt>
                        <dd><Text variant="small">{contactEmail}</Text></dd>
                    </dl>
                    <dl>
                        <dt><Text variant="small" className={styles.label}>Custom domains</Text></dt>
                        { props.customer.customDomains.map(d => <dd><Text variant="small">{d}</Text></dd>)}
                    </dl>
                </div>
                <div className={styles.column}>
                    <dl>
                        <dt><Text variant="small" className={styles.label}>Microsoft ID</Text></dt>
                        <dd><Text variant="small">{props.customer.tenantId}</Text></dd>
                    </dl>
                    <dl>
                        <dt><Text variant="small" className={styles.label}>Microsoft domain</Text></dt>
                        <dd><Text variant="small">{props.customer.microsoftDomain}</Text></dd>
                    </dl>
                    <dl>
                        <dt><Text variant="small" className={styles.label}>Microsoft Customer Agreement - Acceptance Confirmed by Partner&nbsp;<TooltipHost content={tooltipText}><FontIcon iconName='Info' /></TooltipHost></Text></dt>
                        <dd>
                            <div className={styles.flexContainer}>
                                <Text className={styles.marginRight} variant="small">{props.customer.microsoftCustomerAgreementStatus !== 'Accepted' && <FontIcon className={styles.warningText} iconName='Warning' /> }&nbsp;{props.customer.microsoftCustomerAgreementStatus}{props.customer.microsoftCustomerAgreementStatus !== 'Accepted' && " | " }</Text>
                                {props.customer.microsoftCustomerAgreementStatus !== 'Accepted' && !acceptAgreementCommand.isLoading && !showAgreementAcceptanceSuccess && !showAgreementAcceptanceError && <Text variant="small"><Link onClick={() => setAgreementConfirmationDialogIsOpen(true)}>Accept</Link></Text> }
                                { acceptAgreementCommand.isLoading && <Spinner size={SpinnerSize.xSmall} /> }
                                {props.customer.microsoftCustomerAgreementStatus !== 'Accepted' && !acceptAgreementCommand.isLoading && showAgreementAcceptanceSuccess && <Text variant='small' className={styles.successText}>Done</Text> }
                                {props.customer.microsoftCustomerAgreementStatus !== 'Accepted' && !acceptAgreementCommand.isLoading && showAgreementAcceptanceError && <Text variant='small' className={styles.errorText}>Failed. <Link onClick={() => setIsAgreementAcceptanceErrorDialogOpen(true)}>See details</Link></Text> }
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    );

}