import { AxiosError } from 'axios';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import { onErrorHelper, onMutateHelper } from '../../hooks';
import {
    getCustomers,
    getCustomerById,
    getCustomerSubscriptions,
    acceptAgreement} from './calls';
import {IAcceptAgreementRequest, ICustomersQuery} from './models';

export const useCustomers = (query: ICustomersQuery) => useQuery(['CUSTOMERS', query], () => getCustomers(query));

export const useCustomerById = (id: string) => useQuery(['CUSTOMER', id], () => getCustomerById(id));

export const useCustomerSubscriptions = (id: string) => useQuery(['CUSTOMER_SUBSCRIPTIONS', id], () => getCustomerSubscriptions(id));

export const useAcceptAgreement = () => {
    const qc = useQueryClient();

    return useMutation(acceptAgreement, {
        onMutate: (variables: IAcceptAgreementRequest) => onMutateHelper(qc, variables, ['AGREEMENT'], 'update'),
        onError: (err: AxiosError, variables: IAcceptAgreementRequest, context) => onErrorHelper(qc, err, context, ['AGREEMENT'])
    });
}
