import {useMutation, useQuery, useQueryClient} from 'react-query';
import {updateSubscription, getSubscriptionById, getSubscriptionAddOns} from './calls';
import {ISubscriptionUpdateRequest} from './models';
import {onErrorHelper, onMutateHelper, onSettledHelper} from '../../hooks';
import {AxiosError} from 'axios';

export const useSubscriptionById = (tenantId: string, subscriptionId: string) =>
    useQuery(['SUBSCRIPTION_BY_ID', tenantId, subscriptionId], () => getSubscriptionById(tenantId, subscriptionId));

export const useUpdateSubscription = () => {
    const qc = useQueryClient();

    return useMutation(updateSubscription, {
        onMutate: (variables: ISubscriptionUpdateRequest) => onMutateHelper(qc, variables, ['SUBSCRIPTION'], 'update'),
        onError: (err: AxiosError, variables: ISubscriptionUpdateRequest, context) => onErrorHelper(qc, err, context, ['SUBSCRIPTION']),
        onSettled: () => onSettledHelper(qc, ['SUBSCRIPTION']),
    });
}

export const useSubscriptionAddOns = (tenantId: string, subscriptionId: string) =>
    useQuery(['SUBSCRIPTION_ADDONS', tenantId, subscriptionId], () => getSubscriptionAddOns(tenantId, subscriptionId));