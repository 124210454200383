import { ActionButton, getTheme, IIconProps, mergeStyleSets, Spinner, Dialog, IDialogContentProps, IModalProps, DialogFooter, DialogContent, SpinnerSize } from "@fluentui/react";
import React from "react";
import { BaseDialog } from "./BaseDialog";

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        noSelect: {
            userSelect: 'none'
        }
    })
}

const defaultModalProps: IModalProps = {
    isBlocking: false,
    theme
};

const okIcon: IIconProps = { iconName: 'CheckMark' }

export interface IActionDialogState {
    hidden: boolean,
    title: string,
    action: () => void,
    onDismiss: () => void
    noBorder?: boolean,
    isLoading?: boolean,
    loadingMessage?: string,
    isSuccess?: boolean,
    isError?: boolean,
    errorComponent?: JSX.Element,
    successComponent?: JSX.Element,
    dialogContentProps?: IDialogContentProps,
    confirmText?: string,
    confirmIcon?: IIconProps,
    confirmButtonDisabled?: boolean,
    cancelText?: string,
    cancelIcon?: IIconProps,
    maxWidth?: number,
    minWidth?: number
    modalProps?: IModalProps
    children?: React.ReactNode;
}


export const ActionDialog: React.FC<IActionDialogState> = (props) => {

    const [hasActionExecuted, setHasActionExecuted] = React.useState<boolean>();

    const executeAction = () => {
        props.action();
        setHasActionExecuted(true);
    }

    const onDismiss = () => {
        setHasActionExecuted(false);
        props.onDismiss();
    }

    return (
        <BaseDialog
            hidden={props.hidden}
            title={props.title}
            dialogContentProps={props.dialogContentProps}
            maxWidth={props.maxWidth ?? 500}
            minWidth={props.minWidth ?? 500}
            modalProps={props.modalProps ?? defaultModalProps}
            onDismiss={onDismiss}
            noBorder={props.noBorder}
            additionalDialogButtons={[{ shouldRender: !!props.action && !hasActionExecuted, element:  <ActionButton onClick={executeAction} iconProps={props.confirmIcon ?? okIcon} disabled={(!!props.isLoading && props.isLoading) || (!!props.confirmButtonDisabled && props.confirmButtonDisabled)} allowDisabledFocus>{props.confirmText ?? "OK"}</ActionButton> }]}>
            { props.isLoading && <Spinner label={props.loadingMessage ?? "Loading..."} size={SpinnerSize.large} /> }
            { !props.isLoading && hasActionExecuted && props.isError && props.errorComponent }
            { !props.isLoading && hasActionExecuted &&  props.isSuccess && props.successComponent }
            { !props.isLoading && !hasActionExecuted && props.children }
        </BaseDialog>
    );
}