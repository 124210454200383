import React from 'react';
import { Badge } from 'react-bootstrap';
import { mergeStyleSets, Link, Text, getTheme } from '@fluentui/react';
import { IUser } from '../../api/resources/users';
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { ICustomer } from '../../api/resources/customers';
import { UserActionButton } from './UserActionButton';
import { TenantContext } from '../core/context/TenantContextProvider';
export interface IUserListProps {
    customer: ICustomer,
    users: IUser[],
    includeActions?: boolean
    noLabels?: boolean
}

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        tableHeader: {
            color: '#6c757d'
        },
        tableContainer: {
            dixplay: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        table: {
            fontWeight: 'normal',
            tableLayout: 'initial !important',
            borderCollapse: 'separate !important',
            borderColor: 'transparent !important'
        }, 
        rows: {
            boxShadow: `0 0 2px ${theme.palette.neutralSecondaryAlt}`,
            verticalAlign: 'middle'
        },
        mutedText: {
            color: '#787878'
        },
        loading: {
            marginTop: '8rem',
            marginBottom: '8rem',
            fontStyle: 'italic',
            fontSize: '14',
            color: 'white'
        }
    })
}

export const UsersList: React.FC<IUserListProps> = (props) => {
    const tenantContext = React.useContext(TenantContext);
    const navigate = useNavigate();
    const goToUserDetailPage = (userId: string) => {
        const userDetailsPath = tenantContext!.isPartnerUser ? `/customers/${props.customer.tenantId}/users/${userId}` : `/users/${userId}`;
        navigate(userDetailsPath)
    };

    const userNameColumn = (cell:any, user: IUser) => {
        return (
            <div>
                <div className={user.state != 'Active' ? styles.mutedText : ''}><Link onClick={() => goToUserDetailPage(user.id)}>{user.displayName}</Link></div>
                <Text variant="small" className={styles.mutedText}>{user.state === 'Active' ? '' : <Badge bg="warning">{user.state}</Badge>} {user.id}</Text>
            </div>
        );
    };

    const emailColumn = (cell: any, user: IUser) => user.email.toLocaleLowerCase();

    const actionsColumn = (cell: any, user: IUser) => {
        return (<UserActionButton user={user} customer={props.customer} />);
    }

    const columns = [{
        dataField: "displayName",
        text: props.noLabels ? "" : "Name",
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: { fontWeight: 'normal' },
        formatter: userNameColumn
    }, {
        dataField: "email",
        text: props.noLabels ? "" : "Email",
        sort: true,
        align: "left",
        headerAlign: "left",
        headerStyle: { fontWeight: 'normal' },
        formatter: emailColumn
    }]

    if(props.includeActions) {
        columns.push({
            dataField: "id",
            text: "",
            sort: false,
            align: 'left',
            headerAlign: "right",
            headerStyle: { fontWeight: 'normal' },
            formatter: actionsColumn
        });
    }

    return (
      <div className={styles.tableContainer}>
        <BootstrapTable
          keyField="id"
          classes={styles.table}
          headerClasses={styles.tableHeader}
          rowClasses={styles.rows}
          bordered={false}
          data={props.users}
          columns={columns}
        />
      </div>
    );
}