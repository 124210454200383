import { FontIcon, mergeStyleSets, Spinner, SpinnerSize, Text, TooltipHost } from "@fluentui/react";
import React from "react";
import { ICustomer, useCustomerSubscriptions } from "../../../api/resources/customers";
import { SubscriptionsList } from "../SubscriptionsList";

const styles = {
    ...mergeStyleSets({
        pageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        loading: {
            marginTop: '8rem',
            marginBottom: '8rem',
            fontStyle: 'italic',
            fontSize: '14',
            color: 'white'
        },
        subscriptionListContainer: {
            marginTop: '2rem',
            marginBottom: '4rem'
        },
        subscriptionListTitle: {
            textAlign: 'left'
        },
        emptyState: {
            height: '10rem',
            padding: '4rem',
            color: '#787878'
        },
        mutedText: {
            color: '#787878'
        },
    })
};

export interface ISubscriptionsTabProps {
    customer: ICustomer;
}

export const SubscriptionsTab: React.FC<ISubscriptionsTabProps> = (props) => {
    
    const customerSubscriptionsQuery = useCustomerSubscriptions(props.customer.tenantId);
    const annualSubscriptions = customerSubscriptionsQuery.data?.customerSubscriptions.filter(s => s.termDuration == "P1Y").sort((a, b) => (a.status > b.status) ? 1 : -1);
    const monthlySubscriptions = customerSubscriptionsQuery.data?.customerSubscriptions.filter(s => s.termDuration == "P1M").sort((a, b) => (a.status > b.status) ? 1 : -1);
    
    return (
        <div className={styles.pageContainer}>
            { (customerSubscriptionsQuery.isLoading || customerSubscriptionsQuery.isRefetching) && <Spinner className={styles.loading} size={SpinnerSize.large} label={`Getting ${props.customer.companyName}'s subscriptions...`} /> }
            { !customerSubscriptionsQuery.isLoading && !customerSubscriptionsQuery.isRefetching &&
                <div>
                    {annualSubscriptions && annualSubscriptions.length > 0 &&
                        <div className={styles.subscriptionListContainer}>
                            <Text variant="large" block className={styles.subscriptionListTitle}>Annual Commitment Subscriptions&nbsp;<TooltipHost content="Annual subscriptions process quantity increases almost immediately. However, Microsoft stipulates that quantity decreases only occur during renewals. The exception is that after a quantity increase, the increased licenses have a 7-day period they can be subsequently reduced. The quantity of licenses that are available for immediate reduction are displayed as the Refundable Quantity below."><FontIcon iconName="Info" /></TooltipHost></Text>
                            <SubscriptionsList customer={props.customer} subscriptions={annualSubscriptions} />
                        </div>
                    }

                    {monthlySubscriptions && monthlySubscriptions.length > 0 &&
                        <div className={styles.subscriptionListContainer}>
                            <Text variant="large" block className={styles.subscriptionListTitle}>Month-to-month Subscriptions</Text>
                            <SubscriptionsList customer={props.customer} subscriptions={monthlySubscriptions} />
                        </div>
                    }

                    { !customerSubscriptionsQuery.data || customerSubscriptionsQuery.data.customerSubscriptions.length === 0 && 
                        <div className={styles.emptyState}>
                            <Text className={styles.mutedText}><em>{props.customer.companyName} has no subscriptions</em></Text>
                        </div>
                    }
                </div>
            }
        </div>
    );
}