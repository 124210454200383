import React from 'react';
import {ICustomer, useCustomerSubscriptions} from '../../../api/resources/customers';
import {IUserCreateRequest, useCreateUser} from '../../../api/resources/users';
import {ActionButton, Dropdown, Dialog, DialogFooter, DirectionalHint, FontIcon, getTheme, IDialogContentProps, IIconProps, IModalProps, Label, mergeStyleSets, TextField, Toggle, TooltipHost, IDropdownOption, Spinner, SpinnerSize, Text} from '@fluentui/react';
import { Step, Stepper } from 'react-form-stepper';
import { StepStyleDTO } from 'react-form-stepper/dist/components/Step/StepTypes';
import { ISubscription } from '../../../api/resources/subscriptions';
import { validatePassword } from '../services/PasswordValidationService';
import { ErrorMessage } from '../../core/ErrorMessage';
import { SuccessMessage } from '../../core/SuccessMessage';
import { UserLicenseAssignments } from '../UserLicenseAssignments';

const styles = {
    ...mergeStyleSets({
        container: {
            padding: '1rem',
            boxShadow: '0 0 2px #DEDEDE'
        },
        formContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        formColumn: {
            width: '49%'
        },
        textInput: {

        },
        subscriptionListItemBullet: {
            align: 'text-bottom',
            color: '#117492'
        },
        reviewContainer: {
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: '5rem',
            paddingRight: '5rem'
        },
        reviewLicensesContainer: {
            display: 'flex',
            paddingLeft: '5rem',
            paddingRight: '5rem'
        },
        reviewColumn: {
            paddingRight: '1rem',
            flex: 1,
            textAlign: 'left'
        },
        reviewLabel: {
            color: '#787878'
        },
        resultContainer: {
            textAlign: 'center',
            padding: '1rem',
            boxShadow: '0 0 2px #DEDEDE',
        },
        mutedText: {
            color: '#787878'
        },
        successText: {
            color: "#98c652"
        },
        errorText: {
            color: '#ff0033'
        }
    })
}

export interface INewUserModalState {
    hidden: boolean,
    title: string,
    customer: ICustomer,
    onComplete: () => void,
    onDismiss: () => void
    onConfirm?: () => void,
    dialogContentProps?: IDialogContentProps,
    confirmText?: string,
    confirmIcon?: IIconProps,
    cancelText?: string,
    cancelIcon?: IIconProps,
    maxWidth?: number,
    minWidth?: number
    modalProps?: IModalProps
}

const theme = getTheme();
const defaultModalProps: IModalProps = {
    isBlocking: true,
    theme
};

const okIcon: IIconProps = { iconName: 'CheckMark' }
const nextIcon: IIconProps = { iconName: 'ChevronRight' }
const backIcon: IIconProps = { iconName: 'ChevronLeft' }
const cancelIcon: IIconProps = { iconName: 'Cancel' }

export const NewUserModal : React.FC<INewUserModalState> = (props) => {

    const customerSubscriptionsQuery = useCustomerSubscriptions(props.customer.tenantId);
    const createUserCommand = useCreateUser();

    const [currentStep, setCurrentStep] = React.useState<number>(0);

    const [displayName, setDisplayName] = React.useState<string>("");
    const [displayNameErrorMessage, setDisplayNameErrorMessage] = React.useState<string>("");
    const [firstName, setFirstName] = React.useState<string>("");
    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState<string>("");
    const [emailAddress, setEmailAddress] = React.useState<string>("");
    const [emailAddressErrorMessage, setEmailAddressErrorMessage] = React.useState<string>("");
    const [domain, setDomain] = React.useState<string>(props.customer.customDomains[0]);
    const [domainErrorMessage, setDomainErrorMessage] = React.useState<string>("");
    const [generatePassword, setGeneratePassword] = React.useState<boolean>(true);
    const [password, setPassword] = React.useState<string>('');
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState<string>('');
    const [requireReset, setRequireReset] = React.useState<boolean>(true);
    const [selectedLicenses, setSelectedLicenses] = React.useState<Array<{ productId: string, offerName: string }>>([]);

    const stepStyle: StepStyleDTO = {
        activeBgColor: "#117492",
        activeTextColor: "#ffffff",
        completedBgColor: "#98c652",
        completedTextColor: "#ffffff",
        inactiveBgColor: "#e0e0e0",
        inactiveTextColor: "#ffffff",
        size: "2em",
        circleFontSize: "1rem",
        labelFontSize: "0.875rem",
        borderRadius: "50%",
        fontWeight: "500"
    };
    
    const createUser = () => {
        setCurrentStep(currentStep + 1);
        const createUserRequest: IUserCreateRequest = {
            displayName,
            firstName,
            lastName,
            emailAddress: emailAddress.endsWith('@') ? `${emailAddress}${domain}` : `${emailAddress}@${domain}`,
            password,
            usageLocation: 'US',
            forcePasswordChange: requireReset,
            tenantId: props.customer.tenantId,
            licenseAssignments: selectedLicenses.map(license => license.productId)
        };
        createUserCommand.mutate(createUserRequest);
    }

    const close = () => {
        props.onDismiss();
        setDisplayName('');
        setDisplayNameErrorMessage('');
        setFirstName('');
        setFirstNameErrorMessage('');
        setLastName('');
        setLastNameErrorMessage('');
        setEmailAddress('');
        setEmailAddressErrorMessage('');
        setDomainErrorMessage('');
        setGeneratePassword(true);
        setPassword('');
        setPasswordErrorMessage('');
        setRequireReset(true);
        setCurrentStep(0);
        setSelectedLicenses([]);
        if(currentStep === 3) {
            props.onComplete();
        }
    }

    const updateSelectedLicenses = (selected: boolean | undefined, subscription: ISubscription) => {
        const existingLicense = selectedLicenses.find(license => license.productId === subscription.productId && license.offerName === subscription.offerName);

        if(selected) {
            if(!existingLicense) {
                selectedLicenses.push({ productId: subscription.productId, offerName: subscription.offerName });
            }
        } else {
            if(existingLicense) {
                selectedLicenses.splice(selectedLicenses.indexOf(existingLicense), 1);
            }
        }
    }

    const generatedPasswordLabel = (
        <Label>Generate password <TooltipHost directionalHint={DirectionalHint.bottomCenter} content="If enabled, the generated password will be shown after the new user is created"><FontIcon iconName="info" /></TooltipHost></Label>
    );

    const requireResetLabel = (
        <Label>Require reset <TooltipHost directionalHint={DirectionalHint.bottomCenter} content="If enabled, the user will be required to reset their password the next time they sign in"><FontIcon iconName="info" /></TooltipHost></Label>
    );

    const goToNextStep = () => {
        let isValid = true;
        switch(currentStep)
        {
            case 0:
                if(!displayName) {
                    isValid = false;
                    setDisplayNameErrorMessage("Display name is required");
                } else {
                    setDisplayNameErrorMessage("");
                }
                if (!firstName) {
                    isValid = false;
                    setFirstNameErrorMessage("First name is required");
                } else {
                    setFirstNameErrorMessage("");
                }
                if(!lastName) {
                    isValid = false;
                    setLastNameErrorMessage("Last name is required");
                } else {
                    setLastNameErrorMessage("");
                }
                if(!emailAddress) {
                    isValid = false;
                    setEmailAddressErrorMessage("Email address is required");
                } else {
                    setEmailAddressErrorMessage("");
                }
                if(!domain) {
                    isValid = false;
                    setDomainErrorMessage("Domain is required");
                } else {
                    setDomainErrorMessage("");
                }
                if(!generatePassword) {
                    let passwordValidationError = validatePassword(password);
                    isValid = !passwordValidationError;
                    setPasswordErrorMessage(passwordValidationError ?? "");
                }
            break;

            case 1:
            break;

            case 2:
            break;

            case 3:
            break;
        }

        if(isValid) {
            setCurrentStep(currentStep + 1);
        }
    }

    return (
        <Dialog
            hidden={props.hidden}
            title={props.title}
            dialogContentProps={props.dialogContentProps}
            maxWidth={props.maxWidth ?? 450}
            minWidth={props.minWidth ?? 450}
            modalProps={props.modalProps ?? defaultModalProps}
            onDismiss={props.onDismiss}>
            <Stepper activeStep={currentStep}>
                <Step label="Basic Info" styleConfig={stepStyle} />
                <Step label="Licenses" styleConfig={stepStyle} />
                <Step label="Review" styleConfig={stepStyle} />
                <Step label="Done" styleConfig={stepStyle} />
            </Stepper>

            { currentStep === 0 &&
                <div className={styles.container}>
                    <TextField required errorMessage={displayNameErrorMessage} className={styles.textInput} tabIndex={1} label="Display name" defaultValue={displayName} onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setDisplayName(newValue ?? '')} />
                    <div className={styles.formContainer}>
                        <div className={styles.formColumn}>
                            <TextField required errorMessage={firstNameErrorMessage} className={styles.textInput} tabIndex={2} label="First name" defaultValue={firstName} validateOnFocusOut onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setFirstName(newValue ?? '')} />
                            <TextField required errorMessage={emailAddressErrorMessage} className={styles.textInput} tabIndex={4} label="Email address" defaultValue={emailAddress} onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setEmailAddress(newValue ?? '')} />
                            { !generatePassword && <TextField errorMessage={passwordErrorMessage} defaultValue={password} className={styles.textInput} tabIndex={8} type="password" canRevealPassword revealPasswordAriaLabel='Show password' label="Password" validateOnFocusOut onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setPassword(newValue ?? '')} /> }
                            <Toggle defaultChecked={generatePassword} tabIndex={7} label={generatedPasswordLabel} onChange={(_event: React.MouseEvent<HTMLElement>, checked?: boolean) => { setGeneratePassword(checked ?? false); if(checked) setPassword(""); }} />                           
                        </div>
                        <div className={styles.formColumn}>
                            <TextField required errorMessage={lastNameErrorMessage} className={styles.textInput} tabIndex={3} label="Last name" defaultValue={lastName} validateOnFocusOut onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setLastName(newValue ?? '')} />
                            <Dropdown required errorMessage={domainErrorMessage} className={styles.textInput} tabIndex={5} label="Email domain" defaultSelectedKey={'0'} options={props.customer.customDomains.map((domain: string, index: number) => ({ key: index.toString(), text: domain.toLocaleLowerCase() }))} onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => setDomain(option ? option.text : '')} />
                            <Toggle defaultChecked={requireReset} tabIndex={6} label={requireResetLabel} onChange={(_event: React.MouseEvent<HTMLElement>, checked?: boolean) => setRequireReset(checked ?? false)} />
                         </div>
                    </div>
                </div>
            }
            { currentStep === 1 &&  
                <div>
                    { customerSubscriptionsQuery.isLoading && <Spinner size={SpinnerSize.large} label={`Loading ${props.customer.companyName}'s available subscriptions`} /> }
                    { !customerSubscriptionsQuery.isLoading && <UserLicenseAssignments onLicenseAssigned={(subscription) => updateSelectedLicenses(true, subscription)} onLicenseRevoked={(subscription) => updateSelectedLicenses(false, subscription)} tenantId={props.customer.tenantId} />}
                </div>
            }
            { currentStep === 2 && 
                <div className={styles.container}>
                    <div className={styles.reviewContainer}>
                        <div className={styles.reviewColumn}>
                            <dl>
                                <dt><Text variant="small" className={styles.reviewLabel}>First name</Text></dt>
                                <dd><Text variant="small">{firstName}</Text></dd>
                            </dl>
                            <dl>
                                <dt><Text variant="small" className={styles.reviewLabel}>Last name</Text></dt>
                                <dd><Text variant="small">{lastName}</Text></dd>
                            </dl>
                        </div>
                        <div className={styles.reviewColumn}>
                            <dl>
                                <dt><Text variant="small" className={styles.reviewLabel}>Display name</Text></dt>
                                <dd><Text variant="small">{displayName}</Text></dd>
                            </dl>
                            <dl>
                                <dt><Text variant="small" className={styles.reviewLabel}>Password</Text></dt>
                                <dd><Text variant="small">{generatePassword ? "[generated]" : ""}</Text></dd>
                            </dl>
                        </div>
                    </div>
                    <div className={styles.reviewLicensesContainer}>
                        <dl>
                            <dt><Text variant='small' className={styles.reviewLabel}>Licenses</Text></dt>
                            { selectedLicenses.length === 0 && <dd><Text variant="small"><em>No licenses selected</em></Text></dd>}
                            { selectedLicenses.length > 0 && selectedLicenses.map(license => <dd><FontIcon className={styles.subscriptionListItemBullet} iconName="CircleAddition" />&nbsp;<Text variant="small">{license.offerName}</Text></dd>) }
                        </dl>
                    </div>
                    <div className={styles.reviewContainer}>
                        <Text className={styles.mutedText}>*The user has not been created yet. If the information above is correct, click submit to create the user.</Text>
                    </div>
                </div>
            }
            { currentStep === 3 && 
                <div>
                    { createUserCommand.isLoading && <Spinner label={`Creating ${displayName}...`} size={SpinnerSize.large} />}
                    { createUserCommand.isError && 
                        <div className={styles.resultContainer}>
                            <ErrorMessage message={`Could not create user ${displayName}`} axiosError={createUserCommand.error} />
                        </div>
                    }
                    { !createUserCommand.isLoading && !createUserCommand.isError && 
                        <div className={styles.resultContainer}>
                            <SuccessMessage message={`User ${displayName} was created successfully`}>
                                <Text block>Password:</Text>
                                <Text block><code>{createUserCommand.data?.password}</code></Text>
                            </SuccessMessage>
                        </div>
                    }
                </div>
            }

            <DialogFooter>
                <ActionButton onClick={() => close()} disabled={createUserCommand.isLoading} iconProps={props.cancelIcon ?? cancelIcon} allowDisabledFocus>{props.cancelText ?? "Cancel"}</ActionButton>
                { currentStep !== 3 && <ActionButton onClick={() => setCurrentStep(currentStep - 1)} disabled={currentStep === 0} iconProps={backIcon} allowDisabledFocus>{"Go back"}</ActionButton> }
                { currentStep === 2 && <ActionButton onClick={() => createUser()} iconProps={props.confirmIcon ?? okIcon} allowDisabledFocus>{props.confirmText ?? "OK"}</ActionButton> }
                { currentStep !== 2 && currentStep !== 3 && <ActionButton onClick={() => goToNextStep()} iconProps={nextIcon} allowDisabledFocus>Next</ActionButton> }
            </DialogFooter>
        </Dialog>
    );

}