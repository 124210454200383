import React from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useCustomerById} from "../../../api/resources/customers";
import {useSubscriptionById} from "../../../api/resources/subscriptions";
import {IProduct, IProductOrderRequest, useAvailableAddons, useCreateProductOrder} from "../../../api/resources/products";
import {getTheme, Link, mergeStyleSets, Spinner, SpinnerSize} from "@fluentui/react";
import {AxiosError} from "axios";
import {Accordion, Breadcrumb, Card} from "react-bootstrap";
import {CustomerQuickLinks} from "../../customers";
import {format} from "date-fns";

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        cardContent: {
            backgroundColor: theme.palette.white,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            paddingLeft: '5rem',
            paddingRight: '5rem',
            width: '65rem'
        },
        loading: {
            marginBottom: '1rem',
            fontStyle: 'italic',
            fontSize: '14',
            color: 'white'
        },
        cardHeader: {
            fontSize: 28,
            fontWeight: 'bold'
        },
        cardSubHeader: {
            fontSize: 12,
            fontStyle: 'italic',
            color: 'gray',
            paddingBottom: '.5rem'
        },
        quickLinks: {
            display: "flex",
            flexFlow: "wrap",
            justifyContent: "center",
            paddingBottom: '1rem'
        },
        productTitle: {
            display: 'flex'
        },
        accordionHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        productDescription: {
            paddingBottom: '.5rem'
        },
        productDetails: {
            display: 'flex'
        },
        productDetailsColumn: {
            flex: '33.33%'
        },
        productDetailsItemLabel: {
            textDecoration: 'underline'
        },
        productDetailsItem: {
            paddingBottom: '.5rem'
        },
        buyButton: {
            background: '#004059',
            borderColor: 'white',
            color: 'white',
            borderRadius: '5px'
        }
    }),
};

export const AddOnsCard: React.FC = () => {

    const navigate = useNavigate();
    const {tid, sid} = useParams();
    const query = useLocation().search;
    const oid = new URLSearchParams(query).get('oid');
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [billingType, setBillingType] = React.useState<string | null>(null);
    const [selectedProduct, setSelectedProduct] = React.useState<IProduct | null>(null);
    const createProductOrder = useCreateProductOrder();

    let tenantId = '';
    if (tid)
    {
        tenantId = tid;
    }

    let subscriptionId = '';
    if (sid) {
        subscriptionId = sid;
    }

    let offerId = '';
    if (oid) {
        offerId = oid;
    }

    const customerResponse = useCustomerById(tenantId);
    const subscriptionResponse = useSubscriptionById(tenantId, subscriptionId);
    const subscriptionAddOnsResponse = useAvailableAddons(offerId);


    const confirmPurchase = (billingCycle: string, quantity: number, commitmentEndDate: Date) => {

        if (selectedProduct)
        {
            let productSkuId = '';
            let billingTerm = '';

            const annualSku = selectedProduct.skus.find((sku) => {
                return sku.billingPlan.toLowerCase() === 'monthly' && sku.termDuration.toLowerCase() === 'p1y';
            });

            const monthlySku = selectedProduct.skus.find((sku) => {
                return sku.billingPlan.toLowerCase() === 'monthly' && sku.termDuration.toLowerCase() === 'p1m';
            });

            if (billingCycle === 'Annual' && annualSku) {
                productSkuId = annualSku.id;
                billingTerm = 'P1Y';
            } else if (billingCycle === 'Monthly' && monthlySku) {
                productSkuId = monthlySku.id;
                billingTerm = 'P1M';
            }

            const createProductOrderRequest : IProductOrderRequest = {
                tenantId: tenantId,
                productId: selectedProduct?.id,
                skuId: productSkuId,
                quantity: quantity,
                term: billingTerm,
                billingCycle: 'Monthly',
                country: 'US',
                commitmentEndDate: commitmentEndDate
            };

            createProductOrder.mutateAsync(createProductOrderRequest);

            setShowModal(false);
        }
    }

    const cancelPurchase = (event: any) => {
        setShowModal(false);
    }


    const handleProductClick = (productId: string) => (event: any) =>
    {
        navigate(`partner/customers/${tenantId}/products/${productId}`);
    }


    const renderProductHeader = (product: IProduct) => {
        return (
            <div className={styles.accordionHeader}>
                <div className={styles.productTitle}>
                    <Link onClick={handleProductClick(product.id)}>{product.title}</Link>
                </div>
            </div>
        );
    }

    const onBuyClick = (billingType: string, product: IProduct) => (event: any) => {
        setBillingType(billingType);
        setSelectedProduct(product);
        setShowModal(true);
    }

    const renderProductBody = (product: IProduct) => {

        const monthlySku = product.skus.find((sku) => {
            return sku.billingPlan.toLowerCase() === "monthly" && sku.termDuration.toLowerCase() === 'p1m';
        });

        const annualSku = product.skus.find((sku) => {
            return sku.billingPlan.toLowerCase() === "monthly" && sku.termDuration.toLowerCase() === 'p1y';
        });


        return (
            <div>
                <div className={styles.productDescription}>
                    {product.description}
                </div>
                <div className={styles.productDetails}>
                    <div className={styles.productDetailsColumn}>
                        <div className={styles.productDetailsItemLabel}>
                            Annual Unit Price
                        </div>
                        <div className={styles.productDetailsItem}>
                            {annualSku?.erpPrice}
                        </div>
                        <button className={styles.buyButton} onClick={onBuyClick('Annual', product)}>Buy Annual</button>
                    </div>
                    <div className={styles.productDetailsColumn}>
                        <div className={styles.productDetailsItemLabel}>
                            Monthly Unit Price
                        </div>
                        <div className={styles.productDetailsItem}>
                            {monthlySku?.erpPrice}
                        </div>
                        <button className={styles.buyButton} onClick={onBuyClick('Monthly', product)}>Buy Monthly</button>
                    </div>
                </div>
            </div>
        );
    }



    const renderProduct = (product: IProduct) => {
        return (
            <Accordion.Item eventKey={`${product.title}-accordion`}>
                <Accordion.Header>
                    {renderProductHeader(product)}
                </Accordion.Header>
                <Accordion.Body>
                    {renderProductBody(product)}
                </Accordion.Body>
            </Accordion.Item>
        );
    }


    if (subscriptionAddOnsResponse.isFetching || customerResponse.isFetching || subscriptionResponse.isFetching ||
        !subscriptionAddOnsResponse.data || !customerResponse.data || !subscriptionResponse.data)
    {
        return (
            <div className={styles.loading}>
                <p>Hang in there, loading add-ons</p>
                <Spinner size={SpinnerSize.large}/>
            </div>
        );
    }
    else if (subscriptionAddOnsResponse.isError) {
        const error = subscriptionAddOnsResponse.error as AxiosError;
        throw new Error(error.message);
    }
    else if (customerResponse.isError) {
        const error = customerResponse.error as AxiosError;
        throw new Error(error.message);
    }
    else {
        return (
            <div>

                <Card className={styles.cardContent}>
                    <Breadcrumb>
                        <Breadcrumb.Item href={`https://${window.location.host}/partner`}>Customers</Breadcrumb.Item>
                        <Breadcrumb.Item href={`https://${window.location.host}/partner/customers/${tenantId}`}>{customerResponse.data.companyName}</Breadcrumb.Item>
                        <Breadcrumb.Item href={`https://${window.location.host}/partner/customers/${tenantId}`}>Subscriptions</Breadcrumb.Item>
                        <Breadcrumb.Item href={`https://${window.location.host}/partner/customers/${tenantId}/subscriptions/${subscriptionResponse.data.subscription.id}`}>
                            {subscriptionResponse.data.subscription.offerName}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Available Add-Ons</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className={styles.cardHeader}>{subscriptionResponse.data.subscription.offerName}</div>
                    <div className={styles.cardSubHeader}>{process.env.REACT_APP_COMPANY_NAME}</div>
                    <div className={styles.quickLinks}>
                        <CustomerQuickLinks customer={customerResponse.data}/>
                    </div>
                    <div>
                        <Accordion>
                            {subscriptionAddOnsResponse.data.addons.map(renderProduct)}
                        </Accordion>
                    </div>
                </Card>
            </div>
        );
    };
}