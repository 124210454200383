import React from 'react';
import { Checkbox, FontIcon, FontSizes, getTheme, Link, mergeStyleSets, Spinner, SpinnerSize, Text, TooltipHost } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { useSubscriptionById, useUpdateSubscription } from '../../../api/resources/subscriptions';
import { useCustomerById } from '../../../api/resources/customers';
import { Card } from 'react-bootstrap';
import { IOrderedBreadcrumbItem, PageHeader } from '../../core/PageHeader';
import { formatDate, daysFromNow, formatExpirationMessage } from '../../../services/DateFormatService';
import { ActionDialog } from '../../core/ActionDialog';
import { ErrorMessage } from '../../core/ErrorMessage';
import { SuccessMessage } from '../../core/SuccessMessage';
import { UsersList } from '../../users';
import { getIconName } from '../../../services/ProductIconService';
import { TenantContext } from '../../core/context/TenantContextProvider';
import { formatCurrency } from '../../../services/CurrencyFormatService';

const theme = getTheme();
const styles = {
  ...mergeStyleSets({
    cardContent: {
      backgroundColor: theme.palette.white,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      paddingLeft: "5rem",
      paddingRight: "5rem",
      maxWidth: '56rem'
    },
    tabs: {
      marginBottom: "2rem",
    },
    loading: {
      marginTop: "8rem",
      marginBottom: "8rem",
      fontStyle: "italic",
      fontSize: "14",
      color: "white",
    },
    pageTitle: {
      textAlign: "left",
      paddingTop: "2rem",
      paddingBottom: "2rem",
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    titleText: {
      marginLeft: '1rem'
    },
    container: {
      display: "flex",
      alignItems: "stretch"
    },
    column: {
      paddingRight: "2rem",
      flex: 1,
      textAlign: "left",
    },
    warningContainer: {
      margin: 'auto'
    },
    warningText: {
      color: "#ff8800",
      marginBottom: "1rem"
    },
    warningIcon: {
      fontSize: FontSizes.xLargePlus,
      textAlign: 'center'
    },
    nextRenewalIcon: {
      marginLeft: '0.5rem',
      color: "#ff8800"
    },
    tintedBackground: {
      backgroundColor: "#fafafa",
      padding: "1rem",
      marginBottom: '2rem'
    },
    successText: {
      color: '#98c652'
    },
    mutedText: {
      color: '#787878'
    },
    label: {
      color: '#787878'
    },
    textLeft: {
      textAlign: 'left'
    },
    marginTop: {
      marginTop: '2rem'
    },
    marginBottom: {
      marginBottom: '2rem'
    },
    expirationNotice: {
      backgroundColor: 'rgba(255, 136, 0, 0.5)'
    },
    expirationWarning: {
      backgroundColor: 'rgba(255, 0, 51, 0.5)'
    },
    expirationNoticeIcon: {
      fontSize: '14px',
      color: '#ff8800'
    },
    expirationWarningIcon: {
      fontSize: '14px',
      color: '#ff0033'
    }
  }),
};


export const SubscriptionCard: React.FC = () => {

  const tenantContext = React.useContext(TenantContext);
  const isPartner = tenantContext?.isPartnerUser;
  const { subscriptionId, tenantId } = useParams();
  const customerTenantId = tenantId ?? tenantContext!.customerTenantId;
  const customerQuery = useCustomerById(customerTenantId);
  if (customerTenantId !== customerQuery.data?.tenantId) {
    customerQuery.refetch();
  }

  const subscriptionQuery = useSubscriptionById(customerTenantId, subscriptionId!);

  if (subscriptionId !== subscriptionQuery.data?.subscription.id) {
    subscriptionQuery.refetch();
  }

  const updateSubscriptionCommand = useUpdateSubscription();

  const [isAutoRenewModalOpen, setIsAutoRenewModalOpen] = React.useState<boolean>(false);
  const [isSubscriptionStatusModalOpen, setIsSubscriptionStatusModalOpen] = React.useState<boolean>(false);
  const [isSuspensionConfirmed, setIsSuspensionConfirmed] = React.useState<boolean>(false);

  const breadcrumbs: IOrderedBreadcrumbItem[] = [];

  if (customerQuery.data && subscriptionQuery.data) {
    breadcrumbs.push({
      key: customerQuery.data.tenantId,
      text: customerQuery.data.companyName,
      href: isPartner ? `/customers/${customerQuery.data!.tenantId}/subscriptions` : '/subscriptions',
      position: 1
    }, {
      key: 'subscriptions',
      text: 'Subscriptions',
      href: isPartner ? `/customers/${customerQuery.data!.tenantId}/subscriptions` : '/subscriptions',
      position: 2
    }, {
      key: subscriptionQuery.data!.subscription.id,
      text: subscriptionQuery.data!.subscription.offerName,
      href: isPartner ? `/customers/${customerQuery.data!.tenantId}/subscriptions/${subscriptionQuery.data!.subscription.id}` : `/subscriptions/${subscriptionQuery.data!.subscription.id}`,
      isCurrentItem: true,
      position: 3
    });
  }

  const toggleStatus = () => {
    const newStatus = subscriptionQuery.data?.subscription.status === "Suspended" ? "Active" : "Suspended";

    updateSubscriptionCommand.mutateAsync({
      subscriptionId: subscriptionId ?? "",
      tenantId: customerTenantId ?? "",
      newStatus: newStatus
    })
      .then(_ => {
        setIsSuspensionConfirmed(false);
        subscriptionQuery.refetch();
      })
  }

  const toggleAutoRenew = () => {
    const newAutorenewStatus = !subscriptionQuery.data?.subscription.autoRenewEnabled;

    updateSubscriptionCommand.mutateAsync({
      subscriptionId: subscriptionId!,
      tenantId: customerTenantId ?? "",
      autoRenewEnabled: newAutorenewStatus
    })
      .then(_ => {
        subscriptionQuery.refetch();
      });
  }

  const commitmentEndDate = () => subscriptionQuery.data ? new Date(subscriptionQuery.data.subscription.commitmentEndDate) : new Date();
  const isAnnualTerm = () => subscriptionQuery.data!.subscription.termDuration === "P1Y";
  const daysTillExpiration = daysFromNow(commitmentEndDate());

  const renewsOnComponent = () => {

    if (isAnnualTerm()) {
      if (daysTillExpiration <= 30)
        return renewsOnComponents(styles.expirationWarningIcon, styles.expirationWarning);

      if (daysTillExpiration <= 60)
        return renewsOnComponents(styles.expirationNoticeIcon, styles.expirationNotice);
    }
    return renewsOnComponents("", "");
  }

  const renewsOnComponents = (iconStyle: string, valueStyle: string) => {
    return <dl>
      <dt><Text variant="small" className={styles.label}>Renews On&nbsp;
        {isAnnualTerm() && daysTillExpiration <= 60 && <TooltipHost content={formatExpirationMessage(daysTillExpiration)}><FontIcon className={iconStyle} iconName="Warning" /></TooltipHost>}
      </Text></dt>
      <dd><Text variant="small" className={valueStyle}>{formatDate(commitmentEndDate())}</Text></dd>
    </dl>
  }

  const autoRenewValue = () => {
    if (subscriptionQuery.data?.subscription.autoRenewEnabled) {
      return <Text variant="small">Yes</Text>
    } else {

      return <Text variant="small">No&nbsp;
        <TooltipHost content="With auto-renew turned off, these licenses will expire on the last day of this subscription. Please change this setting to continue using this product. Data loss may occur should the subscription be allowed to expire. Data loss will occur after 30 days of the subscription expiration.">
          <FontIcon className={styles.expirationNoticeIcon} iconName="Warning" />
        </TooltipHost>
      </Text>
    }
  }

  return (
    <div>
      <ActionDialog
        hidden={!isAutoRenewModalOpen}
        action={() => toggleAutoRenew()}
        onDismiss={() => setIsAutoRenewModalOpen(false)}
        title={`${subscriptionQuery.data?.subscription.offerName}`}
        confirmText={subscriptionQuery.data ? subscriptionQuery.data.subscription.autoRenewEnabled ? "Disable" : "Enable" : ""}
        errorComponent={<ErrorMessage message={`Sorry, we couldn't ${subscriptionQuery.data?.subscription.autoRenewEnabled ? "disable" : "enable"} autorenew for ${subscriptionQuery.data?.subscription.offerName}`} axiosError={updateSubscriptionCommand.error ?? undefined} />}
        successComponent={<SuccessMessage message={`Autorenew has been enabled for ${subscriptionQuery.data?.subscription.offerName}`}><Text>It may take up to 30 minutes before the subscription status is updated.</Text></SuccessMessage>}
        isLoading={updateSubscriptionCommand.isLoading}
        loadingMessage={`${subscriptionQuery.data?.subscription.autoRenewEnabled ? "Disabling" : "Enabling"} autorenew for ${subscriptionQuery.data?.subscription.offerName}`}
        isError={updateSubscriptionCommand.isError}
        isSuccess={updateSubscriptionCommand.isSuccess}>
        <Text block>Are you sure you want to {subscriptionQuery.data ? subscriptionQuery.data.subscription.autoRenewEnabled ? "disable" : "enable" : ""} autorenew for this subscription?</Text>
        <br />
        <Text block>You will be automatically billed on or before {subscriptionQuery.data ? formatDate(new Date(subscriptionQuery.data.subscription.commitmentEndDate)) : ""} to ensure there is no service interruption.</Text>
      </ActionDialog>

      <ActionDialog
        hidden={!isSubscriptionStatusModalOpen}
        action={() => toggleStatus()}
        onDismiss={() => setIsSubscriptionStatusModalOpen(false)}
        title={`${subscriptionQuery.data?.subscription.offerName}`}
        confirmText={subscriptionQuery.data ? subscriptionQuery.data.subscription.status === "Active" ? "Suspend" : "Activate" : ""}
        confirmButtonDisabled={!isSuspensionConfirmed && subscriptionQuery.data?.subscription.status === "Active"}
        errorComponent={<ErrorMessage message={`Sorry, we couldn't ${subscriptionQuery.data?.subscription.status === "Active" ? "suspend" : "activate"} ${subscriptionQuery.data?.subscription.offerName}`} axiosError={updateSubscriptionCommand.error ?? undefined} />}
        successComponent={<SuccessMessage message={`${subscriptionQuery.data?.subscription.offerName} is now ${subscriptionQuery.data?.subscription.status === "Active" ? "active" : "suspended"}.`}><Text>It may take up to 30 minutes before the autorenew status is updated.</Text></SuccessMessage>}
        isLoading={updateSubscriptionCommand.isLoading}
        loadingMessage={`${subscriptionQuery.data?.subscription.status === "Suspended" ? "Reactivating" : "Suspending"} ${subscriptionQuery.data?.subscription.offerName}...`}
        isError={updateSubscriptionCommand.isError}
        isSuccess={updateSubscriptionCommand.isSuccess}>
        <Text block>Are you sure you want to {subscriptionQuery.data ? subscriptionQuery.data.subscription.status === "Active" ? "suspend" : "re-activate" : ""} {subscriptionQuery.data?.subscription.offerName}?</Text>
        <br />
        {subscriptionQuery.data?.subscription.status === "Active" && <div className={styles.warningContainer}>
          <div className={`${styles.warningText} ${styles.warningIcon}`}><FontIcon iconName="Warning" /></div>
          <Text block className={styles.warningText}>All user data, including emails and files, associated with this subscription and any user assigned a license for this subscription will be deleted. Please contact {process.env.REACT_APP_COMPANY_NAME} if you're not sure what this means.</Text>
          <Checkbox checked={isSuspensionConfirmed} onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => setIsSuspensionConfirmed(checked ?? false)} label='I understand, please suspend the subscription' />
        </div>}
      </ActionDialog>

      <Card className={styles.cardContent}>
        {(customerQuery.isLoading || customerQuery.isRefetching || subscriptionQuery.isLoading || subscriptionQuery.isRefetching) && <Spinner className={styles.loading} size={SpinnerSize.large} label="Getting subscription details..." />}
        {!customerQuery.isLoading && !customerQuery.isRefetching && !subscriptionQuery.isLoading && !subscriptionQuery.isRefetching && subscriptionQuery.data && customerQuery.data &&
          <div>
            <PageHeader customer={customerQuery.data!} pageTitle={subscriptionQuery.data?.subscription.offerName!} breadcrumbs={breadcrumbs} />
            <div>
              <div className={styles.pageTitle}>
                <div className={styles.titleContainer}>
                  <Text block className={styles.mutedText} variant='superLarge'><FontIcon iconName={getIconName(subscriptionQuery.data.subscription.offerName, false)} /></Text>
                  <div className={styles.titleText}>
                    <Text block variant='large'>{subscriptionQuery.data.subscription.offerName}</Text>
                    <Text block variant='small' className={styles.mutedText}>{subscriptionQuery.data.subscription.id}</Text>
                  </div>
                </div>
              </div>
              <div className={`${styles.tintedBackground} ${styles.textLeft}`}>
                <dl className={styles.marginBottom}>
                  <dt><Text variant="small" className={styles.label}>Description</Text></dt>
                  <dd><Text variant="small">{subscriptionQuery.data.subscription.skuDescription}</Text></dd>
                </dl>
                <div className={styles.container}>
                  <div className={styles.column}>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Created on</Text></dt>
                      <dd><Text variant="small">{formatDate(new Date(subscriptionQuery.data.subscription.creationDate))}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Billing Cycle</Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.billingCycle}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Term Duration</Text></dt>
                      <dd><Text variant="small">{isAnnualTerm() ? "1 year" : "1 month"}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Price per license</Text></dt>
                      <dd><Text variant="small">{isAnnualTerm() ? formatCurrency(subscriptionQuery.data.subscription.unitPrice / 12) : formatCurrency(subscriptionQuery.data.subscription.unitPrice)}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Publisher</Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.publisherName}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Effective start date</Text></dt>
                      <dd><Text variant="small">{formatDate(new Date(subscriptionQuery.data.subscription.effectiveStartDate))}</Text></dd>
                    </dl>
                    {renewsOnComponent()}
                  </div>
                  <div className={styles.column}>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Quantity</Text></dt>
                      { /* TODO: Make this a quantity incrementer */}
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.quantity}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Refundable Quantity&nbsp;<TooltipHost content="The quantity of licenses that have been added within the last 7 days and are available to be reduced immediately. Any licenses that were added more than 7 days ago cannot be immediately reduced and require the license reduction to be scheduled for the next renewal date."><FontIcon iconName="Info" /></TooltipHost></Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.refundableQuantity}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Status</Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.status}</Text>{(subscriptionQuery.data.subscription.status === "Active" || subscriptionQuery.data.subscription.status === "Suspended") && <Text> | <Link onClick={() => setIsSubscriptionStatusModalOpen(true)}>{subscriptionQuery.data.subscription.status === "Active" ? "Suspend" : "Activate"}</Link></Text>}</dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Auto renew enabled&nbsp;
                        {<TooltipHost content="Annual subscriptions renew each year on the anniversary date of subscription. Renewal continues the subscription for 12 months."><FontIcon iconName="Info" /></TooltipHost>}
                      </Text></dt>
                      <dd>{autoRenewValue()}{(isAnnualTerm() || subscriptionQuery.data.subscription.termDuration === "P1M") && <Text> | <Link onClick={() => setIsAutoRenewModalOpen(true)}>{subscriptionQuery.data.subscription.autoRenewEnabled ? "Disable" : "Enable"}</Link></Text>}</dd>
                    </dl>
                    {isAnnualTerm() && <dl>
                      <dt><Text variant="small" className={styles.label}>Cancel until</Text></dt>
                      <dd><Text variant="small">{formatDate(new Date(subscriptionQuery.data.subscription.cancellationAllowedUntilDate))}</Text></dd>
                    </dl>}
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Renewal term duration</Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.renewalTermDuration ? subscriptionQuery.data.subscription.renewalTermDuration : "-"}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Contract type</Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.contractType}</Text></dd>
                    </dl>
                  </div>
                  <div className={styles.column}>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Product Id</Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.catalogProductId}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Sku Id</Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.catalogSkuId}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Availability Id</Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.catalogAvailabilityId}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Order ID</Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.orderId}</Text></dd>
                    </dl>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Add-ons available</Text></dt>
                      <dd><Text variant="small">{subscriptionQuery.data.subscription.hasPurchasableAddons ? "Yes" : "No"}</Text></dd>
                    </dl>
                  </div>
                </div>
                {subscriptionQuery.data.subscription.nextTermInstruction &&
                  <div className={styles.column}>
                    <dl>
                      <dt><Text variant="small" className={styles.label}>Updates at Next Renewal<FontIcon className={styles.nextRenewalIcon} iconName="WorkItemAlert" /></Text></dt>
                      {subscriptionQuery.data.subscription.nextTermInstruction.customerTermEndDate && <dd><Text variant="small">Term end date will be {formatDate(subscriptionQuery.data.subscription.nextTermInstruction.customerTermEndDate)}</Text></dd>}
                      {subscriptionQuery.data.subscription.nextTermInstruction.quantity !== subscriptionQuery.data.subscription.quantity && <dd><Text variant="small">Quantity will be reduced to {subscriptionQuery.data.subscription.nextTermInstruction.quantity}</Text></dd>}
                      {subscriptionQuery.data.subscription.nextTermInstruction.productTerm && subscriptionQuery.data.subscription.nextTermInstruction.productTerm.productId !== subscriptionQuery.data.subscription.catalogProductId && <dd><Text variant="small">Product will be updated to {subscriptionQuery.data.subscription.nextTermInstruction.productTerm.productId}</Text></dd>}
                      {subscriptionQuery.data.subscription.nextTermInstruction.productTerm && subscriptionQuery.data.subscription.nextTermInstruction.productTerm.skuId !== subscriptionQuery.data.subscription.catalogSkuId && <dd><Text variant="small">Sku will be updated to {subscriptionQuery.data.subscription.nextTermInstruction.productTerm.skuId}</Text></dd>}
                      {subscriptionQuery.data.subscription.nextTermInstruction.productTerm && subscriptionQuery.data.subscription.nextTermInstruction.productTerm.availabilityId !== subscriptionQuery.data.subscription.catalogAvailabilityId && <dd><Text variant="small">Availability will be updated to {subscriptionQuery.data.subscription.nextTermInstruction.productTerm.availabilityId}</Text></dd>}
                      {subscriptionQuery.data.subscription.nextTermInstruction.productTerm && subscriptionQuery.data.subscription.nextTermInstruction.productTerm.termDuration !== subscriptionQuery.data.subscription.termDuration && <dd><Text variant="small">Term will be updated to {subscriptionQuery.data.subscription.nextTermInstruction.productTerm.termDuration}</Text></dd>}
                    </dl>
                  </div>
                }
              </div>
              <div className={styles.textLeft}><Text>Assigned Users</Text></div>
              <UsersList customer={customerQuery.data} users={subscriptionQuery.data.subscription.assignedUsers} noLabels />
            </div>
          </div>
        }
      </Card>
    </div>
  );
};