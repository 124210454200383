import { FontIcon, mergeStyleSets, Text } from "@fluentui/react";
import { AxiosError } from "axios";
import React from "react";
import { getFormattedErrorMessage } from "./services/ErrorFormattingService";

const styles = {
    ...mergeStyleSets({
        errorText: {
            color: '#ff0033'
        },
        textCenter: {
            textAlign: 'center'
        }
    })
}

export interface IErrorMessageProps {
    message: string
    axiosError?: AxiosError,
    generalError?: Error
}

export const ErrorMessage: React.FC<IErrorMessageProps> = (props) => {
    return (
        <div>
            <div className={styles.textCenter}><Text block variant='xxLargePlus' className={styles.errorText}><FontIcon iconName='Error' /></Text></div>
            <div className={styles.textCenter}><Text block>{props.message}</Text></div>
            <br />
            <div><Text block>{props.axiosError ? getFormattedErrorMessage(props.axiosError) : props.generalError?.message}</Text></div>
        </div>
    );
}