import { ActionButton, IconButton, IIconProps, mergeStyleSets, Spinner, Text, Link, SpinnerSize, PrimaryButton, getTheme } from "@fluentui/react";
import { AxiosError } from "axios";
import { isWithinInterval, sub, subDays } from "date-fns";
import React from "react";
import { ISubscription, ISubscriptionResponse, ISubscriptionUpdateRequest, useUpdateSubscription } from "../../api/resources/subscriptions";
import { formatDate } from "../../services/DateFormatService";
import { BaseDialog } from "../core/BaseDialog";
import { ErrorMessage } from "../core/ErrorMessage";

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        incrementIcon: {
            color: '#117492',
            marginLeft: '1rem',
            marginRight: '1rem',
            fontWeight: 'bold',
            cursor: 'pointer',
            userSelect: 'none'
        },
        saveButton: {
            cursor: 'pointer',
            userSelect: 'none',
            display: 'block',
            margin: 'auto',
            marginTop: '1rem',
            transition: 'all 0.4s'
        },
        saveButtonActive: {
            display: 'block'
        },
        saveButtonInactive: {
            height: '0px',
            borderWidth: '0px !important',
            marginTop: '0px'
        },
        successText: {
            color: '#98C652'
        },
        errorText: {
            color: '#ff0033'
        },
        noSelect: {
            userSelect: 'none'
        },
        disclaimer: {
            marginTop: '1rem'
        }
    })
};

const subtractIcon: IIconProps = { iconName: 'CalculatorSubtract' };
const addIcon: IIconProps = { iconName: 'Add' };
const checkIcon: IIconProps = { iconName: 'CheckboxComposite' };

export interface ILicenseSubscriptionQuantityState {
    tenantId: string,
    subscription: ISubscription
}

export const SubscriptionQuantityIncrementer: React.FC<ILicenseSubscriptionQuantityState> = (props) => {

    const subscriptionMutation = useUpdateSubscription();

    const [quantity, setQuantity] = React.useState<number>(props.subscription.quantity);
    const [originalQuantity, setOriginalQuantity] = React.useState<number>(props.subscription.quantity);

    const [isNextRenewalChange, setIsNextRenewalChange] = React.useState<boolean>(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState<boolean>(false);
    const [isAnnualCommitmentDecreaseModalOpen, setIsAnnualCommitmentDecreaseModalOpen] = React.useState<boolean>(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = React.useState<boolean>(false);
    const [isErrorDisplayed, setIsErrorDisplayed] = React.useState<boolean>(false);
    const [isSuccessDisplayed, setIsSuccessDisplayed] = React.useState<boolean>(false);
    const [errorComponent, setErrorComponent] = React.useState<JSX.Element>();

    const save = () => {
        const requestedQuantityChange = quantity - props.subscription.quantity;
        if (requestedQuantityChange > 0) {
            setIsConfirmationModalOpen(true);
        } else if (requestedQuantityChange < 0) {
            if (props.subscription.isAnnualCommitment) {

                setIsNextRenewalChange(true);
                const hasSufficientRefundableQuantity = Math.abs(requestedQuantityChange) <= props.subscription.refundableQuantity;
                const endDate = new Date(props.subscription.commitmentEndDate);
                const reductionPeriod: Interval = {
                    start: subDays(endDate, 60),
                    end: endDate
                };
                const isWithinReductionPeriod = isWithinInterval(new Date(), reductionPeriod);

                if (hasSufficientRefundableQuantity || isWithinReductionPeriod) {
                    setIsConfirmationModalOpen(true);
                } else {
                    setIsAnnualCommitmentDecreaseModalOpen(true);
                }
            } else {
                setIsConfirmationModalOpen(true);
            }
        }
    };
    const handleError = (error: AxiosError<unknown, any>, request: ISubscriptionUpdateRequest, context?: Record<string, any>) =>  {
        setQuantity(originalQuantity);
        setIsNextRenewalChange(false);

        const errorContent = (
            <ErrorMessage message="Failed to update license quantity" axiosError={error} />
        );
        setErrorComponent(errorContent);
        setIsErrorDisplayed(true);
    }

    const handleSuccess = (response: ISubscriptionResponse, request: ISubscriptionUpdateRequest, context?: Record<string, any>) => {
        setIsErrorDisplayed(false);
        setIsSuccessDisplayed(true);
        setOriginalQuantity(quantity);
        setIsNextRenewalChange(false);
        setTimeout(() => setIsSuccessDisplayed(false), 3000);
    };

    const updateQuantity = () => {
        setIsConfirmationModalOpen(false);
        subscriptionMutation.mutate({
            subscriptionId: props.subscription.id,
            tenantId: props.tenantId,
            newQuantity: quantity
        }, 
        { 
            onError: handleError,
            onSuccess: handleSuccess,
        });
    }

    const incrementQuantity = () => {
        setIsErrorDisplayed(false);
        setQuantity(quantity + 1)
    };

    const decrementQuantity = () => {
        setIsErrorDisplayed(false);
        setQuantity(quantity - 1);
    }

    const resetAndCloseAnnualCommitmentModal = () => {
        setIsErrorDisplayed(false);
        setIsSuccessDisplayed(false);
        setQuantity(props.subscription.quantity);
        setOriginalQuantity(props.subscription.quantity);
        setIsAnnualCommitmentDecreaseModalOpen(false);
        setIsNextRenewalChange(false);
    }
    
    const blockDecrement = () => quantity === 1 || props.subscription.status != 'Active';
    const blockIncrement = () => props.subscription.status != 'Active';
    const blockSave = () => originalQuantity === quantity 
        || quantity < 1;

    return (
        <div className={styles.noSelect}>

            <BaseDialog
                key={`${props.subscription.id}-error`}
                hidden={!isErrorModalOpen} 
                onDismiss={() => setIsErrorModalOpen(false)} 
                title="Could not update subscription">{errorComponent}
            </BaseDialog>

            <BaseDialog
                key={`${props.subscription.id}-annual-block`}
                hidden={!isAnnualCommitmentDecreaseModalOpen}
                onDismiss={() => resetAndCloseAnnualCommitmentModal()}
                title="Annual commitment cannot be decreased">
                    <ErrorMessage 
                        message={`Since this is an annual commitment subscription, the quantity cannot be decreased until the next term renewal on ${formatDate(new Date(props.subscription.commitmentEndDate))}.`} />
            </BaseDialog>

            <BaseDialog
                key={`${props.subscription.id}-quantity`}
                hidden={!isConfirmationModalOpen}
                onDismiss={() => setIsConfirmationModalOpen(false)}
                title="Update subscription quantity"
                additionalDialogButtons={[{ shouldRender: true, element: <ActionButton onClick={() => updateQuantity()} iconProps={{ iconName: "CheckMark" }}>Update</ActionButton> }]}>
                    <Text>Are you sure you want to {quantity - originalQuantity > 0 ? 'increase' : 'decrease'} the license quantity of your {props.subscription.offerName} subscription from {originalQuantity} to {quantity}?</Text>
                <Text className={styles.disclaimer} block variant="small">Note: This process could take up to 60 seconds. Please do not refresh your browser.</Text>
                {isNextRenewalChange ? <Text className={styles.disclaimer} variant="small">This change will take affect after renewal on { formatDate(new Date(props.subscription.commitmentEndDate)) }.</Text> : <Text></Text> }
            </BaseDialog>
            
            { subscriptionMutation.isLoading && <Spinner size={SpinnerSize.small} label="Updating..." labelPosition="left" /> }
            { !subscriptionMutation.isLoading &&
                <div>
                    <div>
                        <IconButton onClick={() => decrementQuantity()} disabled={blockDecrement()} aria-label="Decrement license count by 1" iconProps={subtractIcon} className={styles.incrementIcon} />
                        {quantity}
                        <IconButton onClick={() => incrementQuantity()} disabled={blockIncrement()} aria-label="Increment license count by 1" iconProps={addIcon} className={styles.incrementIcon} />
                        { !subscriptionMutation.isLoading && isErrorDisplayed && <Text block variant="small" className={styles.errorText}>Failed. <Link onClick={() => setIsErrorModalOpen(true)}>See details</Link></Text>}
                        { !subscriptionMutation.isLoading && isSuccessDisplayed && <Text block variant="small"className={styles.successText}>Done</Text> }
                        { !subscriptionMutation.isLoading && !isErrorDisplayed && !isSuccessDisplayed && <PrimaryButton onClick={() => save()} aria-label="Commit new quantity" iconProps={ {...checkIcon, hidden: blockSave()}} className={`${styles.saveButton} ${blockSave() ? styles.saveButtonInactive : styles.saveButtonActive}`}>Save</PrimaryButton> }
                    </div>
                </div>
            }
        </div>
    );
}