import React from 'react';
import {Badge, Card} from 'react-bootstrap';
import {getTheme, mergeStyleSets, FontSizes, IBreadcrumbItem, Spinner, SpinnerSize, Text, FontIcon} from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { ICustomer, useCustomerById } from '../../../api/resources/customers';
import { useProductById, useProducts } from '../../../api/resources/products';
import { IOrderedBreadcrumbItem, PageHeader } from '../../core/PageHeader';
import { getIconName } from '../../../services/ProductIconService';
import { Sku } from '../Sku';
import { TenantContext } from '../../core/context/TenantContextProvider';


const theme = getTheme();
const styles = {
    ...mergeStyleSets({
      cardContent: {
        backgroundColor: theme.palette.white,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        paddingLeft: "5rem",
        paddingRight: "5rem",
        maxWidth: '56rem'
      },
      tabs: {
        marginBottom: "2rem",
      },
      loading: {
        marginTop: "8rem",
        marginBottom: "8rem",
        fontStyle: "italic",
        fontSize: "14",
        color: "white",
      },
      pageTitle: {
        textAlign: "left",
        paddingTop: "2rem",
        paddingBottom: "2rem",
      },
      titleContainer: {
        display: 'flex',
        alignItems: 'center'
      },
      titleText: {
        marginLeft: '1rem'
      },
      container: {
        display: "flex",
        alignItems: "stretch"
      },
      column: {
        paddingRight: "2rem",
        flex: 1,
        textAlign: "left",
      },
      warningContainer: {
        margin: 'auto'
      },
      warningText: {
        color: "#ff8800",
        marginBottom: "1rem"
      },
      warningIcon: {
        fontSize: FontSizes.xLargePlus,
        textAlign: 'center'
      },
      tintedBackground: {
        backgroundColor: "#fafafa",
        padding: "1rem",
        marginBottom: '2rem'
      },
      successText: {
        color: '#98c652'
      },
      mutedText: {
        color: '#787878'
      },
      label: {
        color: '#787878'
      },
      textLeft: {
        textAlign: 'left'
      },
      marginTop: {
        marginTop: '2rem'
      },
      marginBottom: {
        marginBottom: '2rem'
      }
    }),
  };


export const ProductCard: React.FC = () => {

    const tenantContext = React.useContext(TenantContext);
    const { productId, tenantId } = useParams();
    const customerTenantId = tenantId ?? tenantContext!.customerTenantId;
    const customerQuery = useCustomerById(customerTenantId);
    if(customerTenantId !== customerQuery.data?.tenantId) {
        customerQuery.refetch();
    }
    const productQuery = useProductById(productId!);
    if(productId !== productQuery.data?.product.id) {
        productQuery.refetch();
    }

    const productsQuery = useProducts({ page:0, pageSize: 0 });

    const breadcrumbs: IOrderedBreadcrumbItem[] = [];
  
  if(customerQuery.data && productQuery.data) {
      breadcrumbs.push({
        key: 'products',
        text: 'Store',
        href: tenantContext!.isPartnerUser ? `/customers/${customerQuery.data.tenantId}/store` : '/store',
        position: 1
    }, {
        key: productQuery.data!.product.id,
        text: productQuery.data!.product.title,
        href: `/products/${productQuery.data!.product.id}`,
        isCurrentItem: true,
        position: 2
    });
  }

    return (
        <Card className={styles.cardContent}>
            { (customerQuery.isLoading || customerQuery.isRefetching || productQuery.isLoading || productQuery.isRefetching) && <Spinner className={styles.loading} size={SpinnerSize.large} label="Getting product details..." /> }
            { !customerQuery.isLoading && !customerQuery.isRefetching && !productQuery.isLoading && !productQuery.isRefetching && productQuery.data && customerQuery.data &&
                <div>
                    <PageHeader customer={customerQuery.data!} pageTitle={productQuery.data?.product.title!} breadcrumbs={breadcrumbs} />
                    <div>
                        <div className={styles.pageTitle}>
                            <div className={styles.titleContainer}>
                                <Text block className={styles.mutedText} variant='superLarge'><FontIcon iconName={getIconName(productQuery.data.product.title, productQuery.data.product.skus.some(s => s.productSkuPrerequisites.length > 0))} /></Text>
                                <div className={styles.titleText}>
                                <Text block variant='large'>{productQuery.data.product.title}</Text>
                                <Text block variant='small' className={styles.mutedText}>{productQuery.data.product.skus.some(s => s.productSkuPrerequisites) && <Badge bg="secondary">Add-on</Badge>} {productQuery.data.product.id}</Text>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.tintedBackground} ${styles.textLeft}`}>
                            <dl className={styles.marginBottom}>
                                <dt><Text variant="small" className={styles.label}>Description</Text></dt>
                                <dd><Text variant="small">{productQuery.data.product.description}</Text></dd>
                            </dl>
                            <div className={styles.container}>
                                <div className={styles.column}>
                                    <dl>
                                        <dt><Text variant="small" className={styles.label}>Publisher</Text></dt>
                                        <dd><Text variant="small">{productQuery.data.product.publisher}</Text></dd>
                                    </dl>
                                    <dl>
                                        <dt><Text variant="small" className={styles.label}>Market</Text></dt>
                                        <dd><Text variant="small">{productQuery.data.product.market}</Text></dd>
                                    </dl>
                                </div>
                                <div className={styles.column}>
                                    <dl>
                                        <dt><Text variant="small" className={styles.label}>Units</Text></dt>
                                        <dd><Text variant="small">{productQuery.data.product.unitOfMeasure ?? "-"}</Text></dd>
                                    </dl>
                                    <dl>
                                        <dt><Text variant="small" className={styles.label}>Pricing Tier Range</Text></dt>
                                        <dd><Text variant="small">{productQuery.data.product.pricingTierRangeMin} - {productQuery.data.product.pricingTierRangeMax}</Text></dd>
                                    </dl>
                                </div>
                                <div className={styles.column}>
                                    <dl>
                                        <dt><Text variant="small" className={styles.label}>Provisioning ID</Text></dt>
                                        <dd><Text variant="small">{productQuery.data.product.provisioningId}</Text></dd>
                                    </dl>
                                    <dl>
                                        <dt><Text variant="small" className={styles.label}>Provisioning String</Text></dt>
                                        <dd><Text variant="small">{productQuery.data.product.provisioningString}</Text></dd>
                                    </dl>
                                </div>
                            </div>
                            <Text variant='large'>Options</Text>
                            { productQuery.data.product.skus.map((sku, index) => <Sku key={index} allProducts={productsQuery.data ? productsQuery.data.products : []} customer={customerQuery.data} product={productQuery.data.product} sku={sku} expanded />) }
                        </div>
                    </div>
                </div>
            }
        </Card>
    );
}