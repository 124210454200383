import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getProducts, getProductById, createProductOrder, getAvailableAddOns} from './calls';
import {IProductOrderRequest, IProductsQuery} from "./models";
import {onErrorHelper, onMutateHelper, onSettledHelper} from "../../hooks";
import {AxiosError} from "axios";

export const useProducts = (config: IProductsQuery) => useQuery(['PRODUCTS', config], () => getProducts(config));

export const useProductById = (productId: string) => useQuery(['PRODUCT_BY_ID', productId], () => getProductById(productId));

export const useAvailableAddons = (offerId: string) => useQuery(['PRODUCT_ADDONS', offerId], () => getAvailableAddOns(offerId));

export const useCreateProductOrder = () => {
    const qc = useQueryClient();

    return useMutation(createProductOrder, {
        onMutate: (variables: IProductOrderRequest) => onMutateHelper(qc, variables, ['PRODUCT_ORDER'], 'create'),
        onError: (err: AxiosError, variables: IProductOrderRequest, context) => onErrorHelper(qc, err, context, ['PRODUCT_ORDER']),
        onSettled: () => onSettledHelper(qc, ['PRODUCT_ORDER']),
    });
}