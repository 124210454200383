import { format } from "date-fns";

const defaultFormat: string = "M/dd/yyyy";

export const formatDate = (date: Date, formatString?: string): string | undefined => {
    if(!date) {
        return undefined
    }

    if(date.getFullYear() === 1) {
        return '-';
    }

    try {
        return format(date, formatString ?? defaultFormat);
    } catch {
        return date.toString();
    }
}

export const daysFromNow = (date: Date) => {
    const days = daysBetween(date, new Date());
    return Math.ceil(days);
}

export const daysBetween = (date1: Date, date2: Date) => {
    const differenceInTime = date1.getTime() - date2.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays;
}

export const formatExpirationMessage = (daysUntilExpiration: number): string => {
    if(daysUntilExpiration === 0)
        return `This subscription expires today.`;
    
    if(daysUntilExpiration < 0)
        return `This subscription expired ${daysUntilExpiration} day(s) ago.`

    return `This subscription will expire in ${daysUntilExpiration} day(s).`;
}