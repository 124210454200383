import React from 'react';
import {mergeStyleSets, Spinner, SpinnerSize, Text, ICommandBarItemProps, CommandBar, SearchBox } from '@fluentui/react';
import {useUsers} from '../../../api/resources/users';
import {ICustomer} from '../../../api/resources/customers';
import {UsersList} from '../UsersList';
import {NewUserModal} from "../modals/NewUserModal";
import { FluentPaginator } from '../../core/FluentPaginator';

export interface IUsersTabState {
    customer: ICustomer;
}

const styles = {
    ...mergeStyleSets({
        loading: {
            marginTop: '8rem',
            marginBottom: '8rem',
            fontStyle: 'italic',
            fontSize: '14',
            color: 'white'
        },
        pageHead: {
            marginTop: '2rem',
            display: 'flex',
            justifyContent: 'space-between'
        },
        titleContainer: {

        },
        tableTitle: {
            textAlign: 'left'
        },
        toolbarContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        toolbar: {

        },
    }),
};

export const UsersTab: React.FC<IUsersTabState> = (props) => {

    const pageSizeOptions = [10, 25, 50];
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [pageSize, setPageSize] = React.useState<number>(pageSizeOptions[0]);
    const [search, setSearch] = React.useState<string>("");

    const [isNewUserModalOpen, setIsNewUserModalOpen] = React.useState<boolean>(false);

    const usersQuery = useUsers(props.customer.tenantId, { page: currentPage, pageSize, filter: search, tenantId: props.customer.tenantId });

    const openNewUserModal = () => {
        setIsNewUserModalOpen(true);
    }

    const getUsersPage = (page: number) => {
        setCurrentPage(page);
        usersQuery.refetch();
    }

    const commandBarItems: ICommandBarItemProps[] = [{
        key: 'newUser',
        text: 'New User',
        iconOnly: true,
        iconProps: { iconName: 'Add' },
        onClick: () => openNewUserModal()
    }];

    return (
        <div>
            <NewUserModal 
                customer={props.customer}
                hidden={!isNewUserModalOpen}
                onDismiss={() => setIsNewUserModalOpen(false)}
                onComplete={() => usersQuery.refetch()}
                title="Create a new user"
                cancelText="Close"
                confirmText="Submit"
                maxWidth={600}
                minWidth={600} />
            
            <div>  
                <div className={styles.pageHead}>
                    <div className={styles.titleContainer}>
                        <Text variant="large" block className={styles.tableTitle}>
                            Users
                        </Text>
                    </div>
                    <div className={styles.toolbarContainer}>
                        <SearchBox placeholder="Search" 
                            onClear={() => setSearch("")}
                            onEscape={() => setSearch("")}
                            onChange={(_event?: React.ChangeEvent<HTMLInputElement> | undefined, filter?: string | undefined) => setSearch(filter ?? "")}
                            onSearch={(_event?: React.ChangeEvent<HTMLInputElement> | undefined, filter?: string | undefined) => setSearch(filter ?? "")} />
                            <CommandBar className={styles.toolbar} items={commandBarItems} />
                    </div>
                </div>
                { (usersQuery.isLoading || usersQuery.isRefetching || usersQuery.isFetching) && <Spinner label="Getting users..." className={styles.loading} size={SpinnerSize.large} />}
                { !usersQuery.isLoading && !usersQuery.isRefetching && 
                    <div>
                        <UsersList users={usersQuery.data ? usersQuery.data.users : []} customer={props.customer} /> 
                        <FluentPaginator currentPage={currentPage} itemsPerPage={pageSize} itemsPerPageOptions={pageSizeOptions} onPageChange={(page) => getUsersPage(page)} totalItems={usersQuery.data ? search ? usersQuery.data.users.length : usersQuery.data.totalUsers : 0} onItemsPerPageChange={(itemsPerPage) => setPageSize(itemsPerPage)} />
                    </div> 
                }
            </div>
        </div>
    );
}