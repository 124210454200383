import {Endpoint} from '../../base';
import {ICustomer, ICustomersQuery, ICustomersResponse} from './models';
import {ISubscriptionsResponse} from '../subscriptions';
import { IAcceptAgreementRequest } from './models/IAcceptAgreementRequest';

export const getCustomers = (config: ICustomersQuery) => Endpoint
    .get<ICustomersResponse>('/customers', {params: {
        filter: config.filter,
        orderBy: config.orderBy,
        asc: config.asc,
        page: config.page,
        pageSize: config.pageSize
    }})
    .then((r) => r.data);

export const getCustomerById = (id: string) => Endpoint
    .get<ICustomer>(`/customers/${id}`)
    .then((r) => r.data);

export const getCustomerSubscriptions = (id: string) => Endpoint
    .get<ISubscriptionsResponse>(`/customers/${id}/subscriptions`)
    .then((r) => r.data);

export const acceptAgreement = (acceptAgreementRequest: IAcceptAgreementRequest) => Endpoint
    .post<IAcceptAgreementRequest>(`customers/${acceptAgreementRequest.tenantId}/agreements`, {
        tenantId: acceptAgreementRequest.tenantId,
        signerEmail: acceptAgreementRequest.signerEmail,
        signerFirstName: acceptAgreementRequest.signerFirstName,
        signerLastName: acceptAgreementRequest.signerLastName,
        signerPhone: acceptAgreementRequest.signerPhone
    })
    .then((r) => r.data);
