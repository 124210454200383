import React from "react";
import {useAccordionButton} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import {mergeStyleSets} from "@fluentui/react";


const styles = {
    ...mergeStyleSets({
        toggle: {
            marginTop: '.5rem',
            width: '3rem'
        }
    })
}

export interface ICustomToggleState {
    eventKey: any
}

export const CustomToggle: React.FC<ICustomToggleState> = ({eventKey}) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const accordionButtonClick = useAccordionButton(eventKey, () => {
        setOpen(!open);
    });

    if (open) {
        return (
            <Icon.ChevronDown className={styles.toggle} onClick={accordionButtonClick}></Icon.ChevronDown>
        )
    }
    else {
        return (
            <Icon.ChevronUp className={styles.toggle} onClick={accordionButtonClick}></Icon.ChevronUp>
        );
    }
}