import { getTheme, Layer, mergeStyleSets, Spinner, SpinnerSize } from "@fluentui/react";
import { AxiosError } from "axios";
import React from "react";
import { Card } from "react-bootstrap";
import { ITenantResponse, useTenant } from "../../../api/resources/tenants";
import { ErrorCard } from "../ErrorCard";
import { ErrorMessage } from "../ErrorMessage";
import { Header } from "../Header";

const theme = getTheme();
const styles = {
    ...mergeStyleSets({
        mainContent: {
            position: "absolute",
            top: "15%",
            maxWidth: "86%",
            minWidth: "50%",
          },
        cardContent: {
            marginTop: '15%',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            paddingLeft: '5rem',
            paddingRight: '5rem',
            maxWidth: '86%',
            minWidth: '50%',
            margin: 'auto'
        },
        loading: {
            marginTop: '8rem',
            marginBottom: '8rem',
            fontStyle: 'italic',
            fontSize: '14',
            color: 'white'
        }
    }),
};

export interface ITenantContextProviderProps {
    layerHostId: string
    children?: React.ReactNode;
}
export const TenantContext = React.createContext<ITenantResponse | undefined>(undefined);

export const TenantContextProvider: React.FC<ITenantContextProviderProps> = (props) => {

    const tenantInfoQuery = useTenant();

    return (
        <div>
        { (tenantInfoQuery.isLoading || tenantInfoQuery.isRefetching ) && 
            <Layer hostId={props.layerHostId} className={styles.mainContent}>
                <Card className={styles.cardContent}>
                    <Spinner className={styles.loading} size={SpinnerSize.large} label="Authorizing..." />
                </Card>
            </Layer>
        }
        { !tenantInfoQuery.isLoading && tenantInfoQuery.isRefetching && tenantInfoQuery.isError &&
            <ErrorMessage message="Authorization failed" axiosError={tenantInfoQuery.error as AxiosError ?? undefined} />
        }
        { !tenantInfoQuery.isLoading && !tenantInfoQuery.isRefetching &&
            <TenantContext.Provider value={tenantInfoQuery.data}>
                { props.children }
            </TenantContext.Provider>
        }
        </div>
    );
}