import { IIconProps, mergeStyleSets, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { ICustomer } from "../../../api/resources/customers";
import { ISubscription } from "../../../api/resources/subscriptions";
import { IUser, IUserUpdateRequest, useUpdateUser, useUserById } from "../../../api/resources/users";
import { ActionDialog } from "../../core/ActionDialog";
import { ErrorMessage } from "../../core/ErrorMessage";
import { SuccessMessage } from "../../core/SuccessMessage";
import { UserLicenseAssignments } from "../UserLicenseAssignments";

const styles = {
    ...mergeStyleSets({
        container: {
            padding: '1rem'
        },
        textCenter: {
            textAlign: 'center'
        }
    })
}

export interface IManageLicensesModalProps {
    hidden: boolean
    user: IUser
    customer: ICustomer
    onDismiss: () => void
}

export const ManageLicensesModal: React.FC<IManageLicensesModalProps> = (props) => {
    const [licensesToAssign, setLicensesToAssign] = React.useState<string[]>([]);
    const [licensesToRevoke, setLicensesToRevoke] = React.useState<string[]>([]);

    const updateUserCommand = useUpdateUser();

    const close = () => {
        props.onDismiss();
        setLicensesToAssign([]);
        setLicensesToRevoke([]);
    }

    const setSelectedLicenses = (selected: boolean, subscription: ISubscription) => {
        if(selected) {
            if(!licensesToAssign.some(l => l === subscription.productId)) {
                licensesToAssign.push(subscription.productId);
            }
        } else {
            if(!licensesToRevoke.some(l => l === subscription.productId)) {
                licensesToRevoke.push(subscription.productId);
            }
        }
    }

    const updateLicenseAssignments = () => {
        const request: IUserUpdateRequest = {
            tenantId: props.customer.tenantId,
            userId: props.user.id,
            licensesToAssign,
            licensesToRevoke
        }
        updateUserCommand.mutate(request);
    }

   return (
    <ActionDialog
        hidden={props.hidden}
        action={updateLicenseAssignments}
        onDismiss={() => close()}
        noBorder
        title={`${props.user.displayName}'s license(s)`}
        confirmText='Update'
        errorComponent={<ErrorMessage message={`Sorry, we couldn't update ${props.user.displayName}'s license assignments`} axiosError={updateUserCommand.error ?? undefined} /> }
        successComponent={<SuccessMessage message={`${props.user.displayName}'s license assignments have been updated`} />}
        isLoading={updateUserCommand.isLoading}
        loadingMessage={`Updating ${props.user.displayName}'s license assignments...`}
        isError={updateUserCommand.isError}
        isSuccess={updateUserCommand.isSuccess}>
            <UserLicenseAssignments 
                existingLicenses={props.user.licenseAssignments.map(l => l.productSkuId)} 
                tenantId={props.customer.tenantId}
                onLicenseAssigned={(subscription) => setSelectedLicenses(true, subscription)} 
                onLicenseRevoked={(subscription) => setSelectedLicenses(false, subscription)} />
    </ActionDialog>
   );
}